import { getRTPDiscountSteps } from "./rtp-discount-steps";
import { getPriceTableSteps } from "./price-table-steps";
import { getBrandingSteps } from "./branding-steps";
import { getOfferSteps } from "./offers-steps";
import { getProgramSteps } from "./program-steps";
import { getQuestionSteps } from "./question-steps";
export function getSteps(
  isRealGreen,
  editingProgramId,
  programs,
  testCustomerId
) {
  const isRTP =
    isRealGreen &&
    editingProgramId &&
    programs[editingProgramId] &&
    programs[editingProgramId].useRealGreenPricingEngine
      ? true
      : false;

  return {
    // beginning of steps
    "welcome-page": {
      formDataKey: "welcome",
      nextStepOptions: ["company-page"]
    },
    "company-page": {
      formDataKey: "company",
      nextStepOptions: ["company-pricing"]
    },
    "company-pricing": {
      formDataKey: "company",
      nextStepOptions: [isRealGreen ? "real-green-settings" : "bot-settings"]
    },
    "real-green-settings": {
      formDataKey: "company",
      nextStepOptions: [
        testCustomerId ? "bot-settings" : "create-test-customer"
      ]
    },
    "create-test-customer": {
      formDataKey: "company",
      nextStepOptions: ["bot-settings"]
    },
    "bot-settings": {
      formDataKey: "bot",
      nextStepOptions: ["program-page"]
    },
    // program steps
    ...getProgramSteps(isRealGreen, isRTP),

    // if real green and using rtp, setup the per application and pre-pay discounts
    ...getRTPDiscountSteps(),
    // <-- beginning of price table setup -->
    ...getPriceTableSteps(isRTP),
    // begnning of offers substep
    ...getOfferSteps(),
    // program services
    "edit-program-services": {
      formDataKey: "programs",
      propertyToEdit: "programs",
      editing: "editingProgramId",
      nextStepOptions: ["test-pricing"]
    },

    // this is a special setp that exists outside of the form setup, need to handle that logic -- Jonathan
    "test-pricing": {
      nextStepOptions: ["program-page"],
      recordNextStep: false
    },

    // branding steps
    ...getBrandingSteps(),

    //question steps
    ...getQuestionSteps(),
    "final-step": {
      formDataKey: "company",
      recordNextStep: "false"
    }
  };
}
