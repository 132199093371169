import { faPencilAlt, faThList } from '@fortawesome/free-solid-svg-icons'

export const monthDefinition = {
    title: "Over how many months?",
    type: 'array',
    items: {
      type: 'number',
      default: 12,
      enum: [
        1,2,3,4,5,6,7,8,9,10,11,12
      ]
    }
  }
export const autoPayDefinition = {
    title: "Which budget billing method would you like to offer?",
    type: 'array',
    items: {
      type: 'string',
      default: 'First Application Payment',
      enum: [
        "First Application Payment",
        "Monthly Payments"
      ]
    }
  }


export const paymentSchema = {
    "bypass-payment": {
        title: "Would you like to collect payment information?",
        type: "object",
        properties: {
          bypassPayment: {
            type: "boolean",
            default: true,
            title: "Collect Credit Card Infomration?"
          }
        },
        icon: faPencilAlt,
        sidebarDescription: [
          "description here"
        ]
      },
      /// budget billing
      "budget-billing-offered": {
        title: "Do you offer monthly payment options?",
        type: "object",
        properties: {
          bypassPayment: {
            type: "boolean",
            default: false,
            title: "Monthly Payments Offered?"
          }
        },
        icon: faPencilAlt,
        sidebarDescription: [
          "description here"
        ]
      },
      "budget-billing-details": {
        title: "Budget Billing Details",
        description: "Enter the following information",
        type: "object",
        properties: {
          "auto_pay_method": { "$ref": "#/definitions/autoPayMethods" },
        },
        icon: faPencilAlt,
        sidebarDescription: [
          "description here"
        ],
        "dependancies": {
          "auto_pay_method": {
            "oneOf": [
              {
                "properties": {
                  "auto_pay_method": {
                    "enum": "First Application Payment"
                  }
                }
              },
              {
                "properties": {
                  "auto_pay_method": {
                    "enum": "Monthly Payments"
                  },
                  "how_many_months" : { "$ref": "#/definitions/months"}
                }
              }
            ]
          }
        }
      }
}