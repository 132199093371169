import { v4 as uuidv4 } from "uuid";
export function addNewProgram(
  handleChange,
  formData,
  setEditingProgramId,
  handleGoToNextStep,
  isRealGreen
) {
  return async () => {
    const newProgramId = uuidv4();
    await handleChange({
      ...(formData ? formData : {}),
      [newProgramId]: {
        id: newProgramId,
        name: "new program",
        description: "new program description",
        programType: "",
        realGreenApplicationDiscountCode: "[]",
        ...(isRealGreen ? { crmProgramCode: "" } : {})
      }
    });
    setEditingProgramId(newProgramId);
    handleGoToNextStep("edit-program", "program-page");
  };
}
