import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { CircularProgress } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useHistory
} from "react-router-dom";
import ForgotPass from "./components/auth/forgotPass";
import CustomSignIn from "./components/auth/signIn";
import Amplify, { Auth, API } from "aws-amplify";
import awsconfig from "./aws-exports";
import Onboarding from "./components/onboarding/Onboarding.jsx";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
function redirect() {
  setTimeout(function () {
    location.href = "https://growth.lawnbot.biz";
  }, 5000);
}
function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isLawnbotEmployee, userIsLawnbotEmployee] = useState(false);
  const [checkingOnboarding, setCheckingOnboarding] = useState(true);
  const [isOnboarding, setIsOnboarding] = useState(true); // must start as true or redirect will be called

  const fn = async () => {
    const info = await API.get("dashboardapi", `/v1/company`, {});
    setIsOnboarding(info.approved === false ? true : false);
    setCheckingOnboarding(false);
  };

  useEffect(() => {
    async function onLoad() {
      try {
        const data = await Auth.currentSession();
        const idToken = data.getIdToken();
        let email = idToken.payload.email;
        if (/@lawnbot\.biz/.test(email)) {
          userIsLawnbotEmployee(true);
        }
        // console.log(email);
        userHasAuthenticated(true);
      } catch (e) {
        if (e !== "No current user") {
          //onError(e);
        } else {
          // window.location = "/";
          userHasAuthenticated(false);
          const loc = window.location.pathname;
          if (loc === "/") {
          } else if (loc === "") {
          } else {
            window.location = "/";
          }
        }
      }

      setIsAuthenticating(false);
    }
    onLoad();
    fn();
  }, []);

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/login");
  }

  useEffect(() => {
    if (!isOnboarding) {
      redirect();
    }
  }, [isOnboarding]);
  return (
    <div className="App">
      <Router>
        {
          //!isAuthenticating && (
          <div>
            {!isAuthenticated ? (
              <>
                <Route exact path="/">
                  <CustomSignIn />
                </Route>

                <Route exact path="/reset">
                  <ForgotPass />
                </Route>
              </>
            ) : checkingOnboarding ? (
              <div
                style={{
                  height: "100px",
                  textAlign: "center",
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  margin: "auto"
                }}
              >
                <CircularProgress style={{ margin: "auto" }} />
                <span>Checking for Onboarding Status</span>
              </div>
            ) : !checkingOnboarding && !isOnboarding ? (
              <div
                style={{
                  height: "100px",
                  textAlign: "center",
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  margin: "auto"
                }}
              >
                <p>
                  This company has already completed the onboarding process. Now
                  redirecting to our dashboard application...
                </p>
              </div>
            ) : (
              <Onboarding isLawnbotEmployee={isLawnbotEmployee} />
            )}
          </div>
        }
      </Router>
    </div>
  );
}

export default App;
