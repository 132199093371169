export const generateCode = (
  companyId,
  botId,
  companyName = "LawnCare Bot"
) => {
  return `<html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
        <title>${companyName} Lawn Care</title>
        <style>
            .LawnBotFullPage, .LawnBotFullPage-container {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1000;
            }
        </style>
    </head>
    <body onload="">
        <script>
            const botConfig = {
                customerId: '${companyId}',
                botId: '${botId}'
            }
        </script>
        <script>
           function loadBot() {
               const search = window.location.search;
                const body = document.getElementsByTagName('body')[0];
                body.innerHTML = \`
                <div class="LawnBotFullPage">
                    <div class="LawnBotFullPage-container">
                        <iframe frameborder="0" width="100%" height="100%"
                        src="https://sales.lawnbot.biz/\${botConfig.customerId}/\${botConfig.botId}/\${search}"
                        sandbox="allow-popups allow-top-navigation allow-scripts allow-same-origin allow-modals" />
                    </div>
                </div>
                \`;
            }
            setTimeout(() => {
                loadBot();
            }, 500);
        </script>
    </body>
</html>`;
};

export const toCapitalizedWords = (name) => {
  var words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
};

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export function validateProgramsAndCompany(
  programs,
  company,
  botQuestions,
  serviceQuestions,
  botSettings
) {
  let errors = {};

  const requiredCompanyFields = [
    "companyName",
    "customerName",
    "customerEmail",
    "customerPhone",
    "customerAddress",
    "customerCity",
    "customerState",
    "customerZip"
  ];
  //check company
  requiredCompanyFields.forEach((field) => {
    let newLine = false;
    if (!company[field]) {
      newLine = true;
      if (!errors.company) {
        errors.company = { string: "", location: "company-page" };
      }
      errors.company.string += `Your company's ${toCapitalizedWords(
        field
      )} is empty. Please fill this in.`;
    }
    if (newLine) errors.company.string += "\n";
  });
  if (
    !company.maxQuotableSize ||
    company.maxQuotableSize < company.minQuotableSize
  ) {
    if (!errors.company) {
      errors.company = { string: "", location: "company-page" };
    }
    errors.company.string +=
      "Your company's max quotable size must be greater than the mininum quotable size." +
      "\n";
  }
  Object.values(programs).forEach((program) => {
    // check programs
    let programString = "";
    if (!program.programType) {
      programString += `${program.name} has no program type.` + "\n";
    }
    if (program.useRealGreenPricingEngine && !program.crmProgramCode) {
      programString +=
        `${program.name} is an RTP program, but has no real green code.` + "\n";
    } else if (!program.useRealGreenPricingEngine) {
      if (
        !program.useRealGreenPricingEngine &&
        (!program.services || !program.services.length)
      ) {
        programString +=
          `${program.name} is a non-rtp program and has no services.` + "\n";
      } else {
        // check program services
        program.services.forEach((service) => {
          if (!service.priceTableId) {
            programString +=
              `The service ${service.serviceName}, for ${program.name} has no price table attached.` +
              "\n";
          }
          if (!service.cutOffDate) {
            programString +=
              `The service ${service.serviceName}, for ${program.name} has no cut off date.` +
              "\n";
          }
        });
      }
    }
    if (programString) {
      if (!errors.program) {
        errors.program = { string: "", location: "program-page" };
      }
      errors.program.string += programString + "\n";
    }
  });

  botQuestions.forEach((question) => {
    if (question.type !== "freeform") {
      if (!question.options || !question.options.length) {
        if (!errors.botQuestions) {
          errors.botQuestions = { string: "", location: "questions-page" };
        }
        errors.botQuestions.string += `Your bot question '${question.question}' doesn't have any options.`;
      }
    }
  });

  serviceQuestions.forEach((question) => {
    if (question.type !== "freeform") {
      if (!question.options || !question.options.length) {
        if (!errors.serviceQuestions) {
          errors.serviceQuestions = {
            string: "",
            location: "serviceQuestions"
          };
        }
        errors.serviceQuestions.string += `Your bot question '${question.question}' doesn't have any options.`;
      }
    }
  });

  if (!botSettings.zipCodes || !botSettings.zipCodes.trim()) {
    errors.botSettings = {
      string: "No zip codes have been entered for the bot settings",
      location: "bot-settings"
    };
  }
  return errors;
}
