import React from "react";
import { BrandingImage } from ".";
export function BrandingImagePage({ formData, onChange }) {
  return (
    <div>
      <BrandingImage
        field={"companyLogo"}
        aspect={4 / 1}
        fieldName={"Company Logo"}
        allowableContentType={"image/*"}
        image={formData["companyLogo"]}
        formData={formData}
        handleChange={onChange}
      />

      <BrandingImage
        field={"companyAvatar"}
        fieldName={"Company Avatar"}
        aspect={1 / 1}
        circular={true}
        allowableContentType={"image/*"}
        image={formData["companyAvatar"]}
        formData={formData}
        handleChange={onChange}
      />

      <BrandingImage
        field={"valueAddImage"}
        fieldName={"Value Add Image"}
        allowableContentType={"image/*"}
        aspect={4 / 1}
        image={formData["valueAddImage"]}
        formData={formData}
        handleChange={onChange}
      />

      <BrandingImage
        field={"aboutUsAssetUrl"}
        fieldName={"About Us"}
        allowableContentType="video/mp4,image/*"
        aspect={4 / 1}
        image={formData["aboutUsAssetUrl"]}
        formData={formData}
        handleChange={onChange}
      />
    </div>
  );
}
