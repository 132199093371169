import { BotSettings } from "../../step-component/custom-steps/bot/BotSettings.jsx";
export function getBotUI() {
  return {
    "bot-settings": () => {
      return {
        "ui:FieldTemplate": BotSettings
      };
    }
  };
}
