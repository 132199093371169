import React, { useEffect, useState, Fragment } from "react";
import { GetOnboardingData } from "../../../../context/onboarding-data";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";

import { EditOfferProgramRow } from "./EditOfferProgramRow.jsx";

function addProgram(programsArray = [], updateProgram, defaultProgram) {
  updateProgram([
    ...programsArray,
    {
      programId: defaultProgram.id,
      discountType: "discounted-price-table",
      appliedTo: "first-app",
      discountAmount: 0
    }
  ]);
}

function removeProgram(
  programsArray = [],
  updateProgram,
  indexOfProgramToRemove
) {
  const arrayCopy = [...programsArray];
  arrayCopy.splice(indexOfProgramToRemove, 1);
  updateProgram(arrayCopy);
}

function updateProgram(
  programsArray = [],
  updateProgram,
  indexOfProgramToUpdate
) {
  return (programUpdate) => {
    const arrayCopy = [...programsArray];
    arrayCopy[indexOfProgramToUpdate] = programUpdate;
    updateProgram(arrayCopy);
  };
}

export function EditOfferProgramArray({ formData, onChange }) {
  const [offerPrograms, setOfferPrograms] = useState(formData);
  useEffect(() => {
    setOfferPrograms(formData);
  }, [formData]);
  const { programs, priceTables } = GetOnboardingData();
  const filteredPrograms = Object.values(programs).filter((program) => {
    return !program.useRealGreenPricingEngine;
  });
  return (
    <Fragment>
      <Typography style={{ marginBottom: 20 }} variant={"h5"}>
        Programs
      </Typography>
      <div style={{ overflowX: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Program</TableCell>
              <TableCell>Discount Type</TableCell>
              <TableCell>Discount Applied To</TableCell>
              <TableCell>Value</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offerPrograms.map((offerProgram, index) => {
              return (
                <EditOfferProgramRow
                  key={index}
                  priceTables={priceTables}
                  program={offerProgram}
                  availablePrograms={filteredPrograms}
                  updateProgramsArray={updateProgram(formData, onChange, index)}
                  removeProgram={() => removeProgram(formData, onChange, index)}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div
        style={{
          float: "right",
          width: "100%",
          marginTop: 20,
          textAlign: "right"
        }}
      >
        <Button
          variant="contained"
          color={"primary"}
          style={{ marginTop: 10 }}
          onClick={() => {
            addProgram(formData, onChange, filteredPrograms[0]);
          }}
        >
          Add Program to Offer
        </Button>
      </div>
    </Fragment>
  );
}
