import {
  ServicesArray,
  ServiceRow
} from "../../step-component/custom-steps/services";
import { getBrandingUI } from "./branding-ui.js";
import { getProgramUI } from "./program-ui.js";
import { getPriceTableUI } from "./price-table-ui.js";
import { getRTPDicsountUI } from "./rtp-discount-ui";
import { getOfferUI } from "./offer-ui";
import { getTestPricingUI } from "./testing-ui";
import { getQuestionsUI } from "./questions-ui";
import { getCompanyUI } from "./company-ui";
import { getWelcomeUI } from "./welcome-ui";
import { getBotUI } from "./bot-ui";
import { FinalStep } from "../../step-component/custom-steps/final/FinalPage.jsx";
export const uiSchema = {
  ...getWelcomeUI(),
  ...getCompanyUI(),
  ...getProgramUI(),
  ...getBrandingUI(),
  ...getPriceTableUI(),
  ...getRTPDicsountUI(),
  ...getOfferUI(),
  ...getTestPricingUI(),
  ...getQuestionsUI(),
  ...getBotUI(),
  "edit-program-services": () => {
    return {
      services: {
        "ui:ArrayFieldTemplate": ServicesArray,
        items: {
          "ui:FieldTemplate": ServiceRow
        }
      }
    };
  },
  "final-step": () => {
    return {
      "ui:FieldTemplate": FinalStep
    };
  }
};
