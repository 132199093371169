import { faPencilAlt, faRobot, faThList } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@material-ui/core';

export const welcomeSchema = {
  "welcome-page": {
    type: "object",
    title: "Lawnbot Help Section",
    icon: faRobot,
    sidebarDescription: (<div>
        <Typography
                    paragraph={true}
                    key={"description_" + Math.random()}
                    className="step-description"
                  >
                   Do you have questions?  Look in this panel to find answers!
                  </Typography>
                  <hr />
  
                  <Typography
                    paragraph={true}
                    key={"description_" + Math.random()}
                    className="step-description"
                  >
                   Setting up your new Lawnbot isn&apos;t that difficult but does require some configuration to sell your services.  
                   And sometimes we are unsure how these configuration choices can affect your Lawnbot.
     
                  </Typography>
                    <br/>
                  <Typography
                    paragraph={true}
                    key={"description_" + Math.random()}
                    className="step-description"
                  >
                  
                    In this help panel we explain what each setting is for and how it affects your Lawnbot.
                  </Typography>
                  
      </div>)
  }
};
