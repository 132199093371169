import React, { useState, Fragment, useEffect } from "react";
import {
  Button,
  Typography,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";

import { QuestionOptions } from "./QuestionOptions.jsx";
import { QuestionPreview } from "./QuestionPreview.jsx";
import "./question.css";

function addNewOption(type, optionArray = []) {
  let newObject = {};
  switch (type) {
    case "default":
      newObject = { text: "text option" };
      break;
    case "image-text-option":
      newObject = {
        text: "image option",
        image: "https://via.placeholder.com/150"
      };
      break;
    case "icon-text-option":
      newObject = { text: "icon option", iconClass: "thumbs-up" };
      break;
    default:
      break;
  }
  return [...(optionArray ? optionArray : []), newObject];
}

function updateQuestion(item, field, value, updateForm, setState) {
  const updatedObject = {
    ...item,
    options: field === "type" ? null : item.options,
    [field]: value
  };
  setState(updatedObject);
  updateForm(updatedObject);
}

const typeOptions = [
  { id: "freeform", value: "Free Form Answer" },
  { id: "default", value: "Text Option" },
  { id: "image-text-option", value: "Image Option" },
  { id: "icon-text-option", value: "Icon Option" }
];
export function EditQuestionPage({ formData, onChange }) {
  const [item, setItem] = useState(formData);
  useEffect(() => {
    if (formData) {
      setItem(formData);
    }
  }, [formData]);

  return (
    <div>
      <Typography style={{ marginBottom: 20 }} variant={"h5"}>
        Edit Question
      </Typography>
      <TextField
        id="question"
        label="Question"
        style={{ marginTop: 8, width: "100%" }}
        value={item.question}
        margin="normal"
        onChange={(e) => {
          updateQuestion(item, "question", e.target.value, onChange, setItem);
        }}
        InputLabelProps={{
          shrink: true
        }}
        validators={["required"]}
      />

      <Select
        id="type"
        style={{ marginTop: 14, width: "100%" }}
        value={item.type}
        onChange={(e) =>
          updateQuestion(item, "type", e.target.value, onChange, setItem)
        }
        validators={["required"]}
      >
        {typeOptions.map((i) => {
          const key = "menu-item-" + Math.floor(Math.random() * 100000);
          return (
            <MenuItem key={key} value={i.id}>
              {i.value}
            </MenuItem>
          );
        })}
      </Select>

      {(item.type.includes("option") || item.type === "default") && (
        <Fragment>
          <div id="question-options-button-container">
            <Button
              id="question-options-button"
              variant="contained"
              onClick={() =>
                updateQuestion(
                  item,
                  "options",
                  addNewOption(item.type, item.options),
                  onChange,
                  setItem
                )
              }
            >
              <i
                style={{
                  color: "green",
                  marginRight: 5
                }}
                className="fas fa-plus-circle"
              ></i>
              Add Option
            </Button>
          </div>
          <p
            style={{
              textAlign: "left",
              paddingLeft: 5,
              marginBottom: 2,
              fontSize: 10
            }}
          >
            Created Options
          </p>
          <div id="question-options">
            {item.options && item.options.length ? (
              <QuestionOptions
                item={item}
                onChange={onChange}
                setItem={setItem}
              />
            ) : (
              <div
                style={{
                  height: "100px",
                  textAlign: "center",
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  margin: "auto",
                  fontSize: 14
                }}
              >
                <p>{`You've created no options. Click the 'Add Option' button to create options.`}</p>
              </div>
            )}
          </div>
        </Fragment>
      )}
      <QuestionPreview item={item} />
    </div>
  );
}
