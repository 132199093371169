import {
  faBolt,
  faCashRegister,
  faListOl,
  faMoneyBill,
  faPencilAlt,
  faReceipt,
  faThList
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

export const serviceTypesDefinition = {
  title: "What service type is this?",
  type: "array",
  items: {
    type: "string",
    default: "Perimeter Pest",
    enum: ["Perimeter Pest", "Mowing", "Tick & Flea Control", "Etc"]
  }
};

export const programSchema = {
  "program-page": {
    type: "object",
    title: "Programs",
    icon: faThList,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Let&apos;s go ahead and setup your programs!
        </Typography>
        <hr />

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          {`Programs are a description of the service programs in which you offer
          to your customers detailing the number of services, service dates, cut
          off dates, pricing, and discounts. Click the 'Create Program' button to add a new program and begin editing it.`}
        </Typography>

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          <b>Note:</b>
          {` Clicking 'Create Program' or the 'Edit' button on an existing program will take you through the setup and price testing process for the program. You will return to this page once you have finished creating/editing a program.`}
        </Typography>
      </div>
    )
  },
  "edit-program": {
    type: "object",
    title: "Program Details",
    navigationButtons: [null, "Continue Program Setup"],
    properties: {
      name: { type: "string", label: "Name", default: "new program" },
      description: {
        type: "string",
        label: "Description",
        default: "program description"
      },
      programType: { type: "string" },
      crmProgramCode: { type: "string", default: "" }
    },
    icon: faPencilAlt,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Tell Us About Your Program!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Enter information about your program so your future customers know
          what they&apos;ll be purchasing.
        </Typography>
        <ul className="step-description-ul">
          <li>
            <u>Program Name</u> - Enter a descriptive title (e.g. &apos;Weekly
            Lawn Mowing&apos;, &apos;Seasonal Fertilization &amp; Grub
            Application&apos;)
          </li>
          <li>
            <u>Program Description</u> - Explain addtional details of this
            program and what problem is solved by purchasing this program
          </li>
          <li>
            <u>Service Type</u> - Select the &apos;Service Type&apos; which best
            describes this program
          </li>
          <li>
            <u>Real Green Program Code</u> - ( for Real Green Customers Only )
          </li>
        </ul>
      </div>
    )
  },
  "edit-program-using-rtp": {
    title: "Will this program be using real time pricing?",
    type: "object",
    properties: {
      useRealGreenPricingEngine: {
        type: "boolean",
        default: false,
        title: "Use RTP"
      }
    },
    icon: faBolt,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Pull Pricing Directly From Your Service Assistant
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Real Green Realtime Pricing allows Lawnbot to pull pricing directly
          from your Real Green Service Assistant vs loading price tables
          manually into Lawnbot.
        </Typography>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          We highly recommend enabling this feature for all our Real Green
          clients to eliminating data entry errors and changes that occur in
          your business. With this feature enabled, to make pricing/service
          changes in your Lawnbot you will simply need to make those changes in
          your Service Assistant.
        </Typography>
      </div>
    )
  },
  "edit-program-discounts-question": {
    title: "Would you like to setup discounts?",
    type: "object",
    properties: {
      usingDiscounts: {
        type: "boolean",
        default: false,
        title: "Setup Discounts"
      }
    },
    icon: faReceipt,
    sidebarDescription: ["description here"]
  },
  "edit-program-type-of-discount": {
    title: "Would you like to apply discounts to specific applications?",
    type: "object",
    properties: {
      usingApplicationDiscounts: {
        type: "boolean",
        default: false,
        title: "Setup Application Discounts"
      }
    },
    icon: faMoneyBill,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Do You Offer Special Offers On The 1st or Last Round?
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Lawnbot let&apos;s you apply discounts on a per application basis to
          give potential customers discounts and make your offer more
          attractive.
        </Typography>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          For example, you can setup your Lawnbot to give a 50% discount on the
          first application when purchasing this program.
        </Typography>
      </div>
    )
  },
  "edit-program-application-discounts": {
    type: "object",
    title: "Application Discounts",
    properties: {
      realGreenApplicationDiscountCode: { type: "string" }
    },
    icon: faListOl,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Add Discount Codes To Your Applications!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          This is a list of all the current discounts added to this program. You
          can add as my discounts as you like to this program and combine them
          to make your offer stand out. If you don&apos;t see any... don&apos;t
          worry... click the &apos;Add&apos; button
        </Typography>
        <ul className="step-description-ul">
          <li>
            <u>Round</u> - Which # application would you like to apply this
            discount to
          </li>
          <li>
            <u>Sliding Scale</u> - If you want to always off this discount on X
            round regardless where they fall in the season then enable this.
          </li>
          <li>
            <u>Discount Code</u> - The Real Green Discount Code from Service
            Assistant
          </li>
          <li>
            <u>Lower Square Footage</u> - Only apply this discount when the
            measured square footage is at/above this number
          </li>
          <li>
            <u>Upper Square Footage</u> - Only apply this discount when the
            measured square footage is at/below this number
          </li>
        </ul>
      </div>
    )
  },
  "edit-program-using-pre-pay": {
    title: "Would you like to apply a pre-pay discount for the entire program?",
    type: "object",
    properties: {
      usingRTPPrePay: {
        type: "boolean",
        default: false,
        title: "Setup PrePay Discounts"
      }
    },
    icon: faCashRegister,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Pre-Pay Discounts Let&apos;s Your Customers Save!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Offering an additional Pre-Pay discount ( on top of any application
          discounts) can entice customers to purchase.
        </Typography>
      </div>
    )
  },
  "edit-program-prepay-discount": {
    type: "object",
    title: "Pre-Pay Discount",
    properties: {
      realGreenPrepayDiscountCode: { type: "string", default: "" }
    },
    icon: faCashRegister,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Connect Discount From Service Assistant
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Choose the Real Green Discount Code to apply from your Real Green
          Service Assistant
        </Typography>
      </div>
    )
  },
  "test-pricing": {
    title: "Test Program Pricing",
    navigationButtons: ["Previous Step", "Back to Programs Page"],
    icon: faCashRegister,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Test Pricing Before Going Live!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Let&apos;s go ahead and test pricing based on the parameters which you
          have provided. If you don&apos;t see the results that you expect...
          please re-check the information that you&apos;ve entered.
        </Typography>
        <ul className="step-description-ul">
          <li>
            <u>Select Program To Test</u> - Choose program to test pricing
          </li>
          <li>
            <u>Square Footage</u> - Enter square footage (1000, 2000,3000)
          </li>
        </ul>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          *** Only for Programs using Real Time Pricing (RTP) ***
        </Typography>
        <ul className="step-description-ul">
          <li>
            <u>RG Customer ID</u> - The Real Green Customer ID is a valid user
            in your Real Green Service Assistant.
          </li>
        </ul>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          *** What is this RG Customer ID? ***
        </Typography>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          The Real Green Customer ID is a valid user in your Real Green Service
          Assistant. We <u>HIGHLY RECOMMEND</u> creating a new user for testing
          purposes as old customers IDs may have legacy pricing information
          attached. Once you have created/selected a valid customer ID... Please
          enter it in this screen and enter a square footage to see pricing.
        </Typography>
      </div>
    )
  }
};
