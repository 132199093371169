import React from "react";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Typography
} from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data.js";
import { OfferTableRow } from "./OfferTableRow.jsx";
import { v4 as uuidv4 } from "uuid";

async function addNewOffer(
  formData,
  updateOffersObject,
  setEditing,
  goToEditStep
) {
  const newOfferId = uuidv4();
  await updateOffersObject({
    ...(formData ? formData : {}),
    [newOfferId]: {
      name: "new offer",
      active: false,
      startDate: "",
      endDate: "",
      programs: [],
      id: newOfferId
    }
  });
  setEditing(newOfferId);
  goToEditStep("edit-offer", "edit-offers-table");
}

function removeOffer(formData, updateOffersObject) {
  return (offerId) => {
    const newOffersObject = { ...formData };
    delete newOffersObject[offerId];
    updateOffersObject({
      ...newOffersObject
    });
  };
}

export function Offers({ formData, onChange }) {
  const { goToNextStepFunc, setEditingOfferId } = GetOnboardingData();
  const offerRows = Object.keys(formData ? formData : {}).map((id, index) => {
    return (
      <OfferTableRow
        setEditingOfferId={setEditingOfferId}
        deleteOffer={removeOffer(formData, onChange)}
        key={`offer-row-${index}`}
        offer={formData[id]}
        handleGoToNextStep={goToNextStepFunc}
      />
    );
  });
  return (
    <div>
      <Box style={{ marginBottom: 10 }}>
        <Typography variant={"h5"}>Edit Program - Special Offers</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <Button
        style={{ float: "right", position: "relative", marginBottom: 10 }}
        variant="text"
        onClick={() =>
          addNewOffer(formData, onChange, setEditingOfferId, goToNextStepFunc)
        }
      >
        <i
          style={{ color: "green", marginRight: 5 }}
          className="fas fa-plus-circle"
        ></i>
        Create Offer
      </Button>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Active</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{offerRows}</TableBody>
      </Table>
    </div>
  );
}
