import React, { useState, useEffect, useCallback } from "react";
import { Box, Container, Button, Typography } from "@material-ui/core";
import "./step.css";
import Form from "@rjsf/material-ui";
import { stepSchemaIndex } from "../schemas/step-schemas";
import { uiSchema } from "../schemas/ui-schemas";
import { API } from "aws-amplify";
import { TestPricing } from "./custom-steps/price-testing/TestPricing.jsx";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GetOnboardingData } from "../context/onboarding-data";
const defaultSchema = {
  title: "Todo",
  type: "object"
};

// conduct the comparison defined in the step json if there are optional paths
// camparison is the comparison object from the step json and the dataToCheck is the value from the form data
// that needs to be checked. If return value is valse, first route will be taken based on the nextStepOptions (the 0 index).
// If true the second route will be taken
function executeNextStepComparison(comparison, dataToCheck) {
  if (comparison) {
    const { value, operator } = comparison;
    return eval(`${dataToCheck}${operator}${value}`);
  }
  return false;
}

export default function OnboardingStep({
  stepName,
  recordNextStep = true,
  nextStepComparison,
  nextStepOptions,
  previousStep,
  stepTakenBookmarks = {},
  formData,
  goToNamedStep,
  isActive,
  nextStep,
  recordStepTaken,
  handleChange,
  saveData,
  loading,
  initialUserIsOnStep,
  isFirstStep,
  isLastStep,
  setInitialUserIsOnStep
}) {
  const { goToNextStepFunc, setGoToNextStepFunc } = GetOnboardingData();
  const importedUiSchema =
    stepName && uiSchema[stepName] ? uiSchema[stepName] : null;
  // want a simple way to build out forms for each step, but also need to switch to a more custom component for something more complex
  let actualSchema =
    stepName && stepSchemaIndex[stepName]
      ? stepSchemaIndex[stepName]
      : defaultSchema;

  //for when theres optional paths
  const { field } = nextStepComparison || {};

  const [nextStepValue, setNextStepValue] = useState(
    nextStepOptions
      ? nextStepOptions[
          formData &&
          field &&
          executeNextStepComparison(nextStepComparison, formData[field])
            ? 1
            : 0
        ]
      : ""
  );

  useEffect(() => {
    if (!loading && initialUserIsOnStep) {
      goToNamedStep(initialUserIsOnStep);
      setInitialUserIsOnStep("");
    }
  });

  // rerun the check for what the next step will be every time the form is updated
  useEffect(() => {
    if (
      isActive &&
      nextStepOptions &&
      nextStepOptions.length > 1 &&
      formData &&
      field
    ) {
      const check = +executeNextStepComparison(
        nextStepComparison,
        formData[field]
      );
      if (nextStepValue !== nextStepOptions[check]) {
        setNextStepValue(nextStepOptions[check]);
      }
    } else if (
      isActive &&
      nextStepOptions &&
      nextStepOptions[0] &&
      nextStepOptions[0] !== nextStepValue
    ) {
      setNextStepValue(nextStepOptions[0]);
    }
  }, [
    formData,
    nextStepOptions,
    field,
    isActive,
    nextStepComparison,
    nextStepValue
  ]);

  const handleGoToNextStep = useCallback(
    (nextStepValue, currentStep, editingKeys) => {
      nextStepValue ? goToNamedStep(nextStepValue) : nextStep();
      if (recordNextStep) {
        recordStepTaken(currentStep, nextStepValue);
      }
      saveData(nextStepValue, editingKeys);
    },
    [goToNamedStep, nextStep, recordStepTaken, saveData, recordNextStep]
  );

  useEffect(() => {
    if (isActive && !goToNextStepFunc && setGoToNextStepFunc) {
      setGoToNextStepFunc(() => {
        return handleGoToNextStep;
      });
    }
  }, [isActive, goToNextStepFunc, setGoToNextStepFunc, handleGoToNextStep]);

  function handleGoToPreviousStep() {
    const recordedPrevStep = stepTakenBookmarks[stepName];
    recordedPrevStep ? goToNamedStep(recordedPrevStep) : previousStep();
    saveData(recordedPrevStep);
  }

  return (
    <Box id="step">
      <Container id="left-step-container" className="step-container">
        <div id="step-form">
          {/* {editingProgramId ? (
            <Button
              variant="text"
              style={{ float: "right" }}
              color={"primary"}
              onClick={() => goToNamedStep("program-page")}
            >
              Back To Programs
            </Button>
          ) : null} */}
          {stepName === "test-pricing" ? (
            <TestPricing isActive={isActive} />
          ) : (
            <Form
              schema={actualSchema}
              formData={formData}
              uiSchema={importedUiSchema ? importedUiSchema() : {}}
              onChange={_.debounce((e) => {
                isActive ? handleChange(e.formData) : () => {};
              }, 500)}
            >
              <div></div>
            </Form>
          )}
        </div>
        <div id="step-form-button-container">
          {!isFirstStep && !actualSchema.navigationButtons && (
            <Button onClick={handleGoToPreviousStep}>
              <i
                style={{ marginRight: 10 }}
                className="fas fa-angle-left fa-2x"
              ></i>
              Previous Step
            </Button>
          )}
          {!isLastStep && !actualSchema.navigationButtons && (
            <Button onClick={() => handleGoToNextStep(nextStepValue, stepName)}>
              Next Step
              <i
                style={{ marginLeft: 10 }}
                className="fas fa-angle-right fa-2x"
              ></i>
            </Button>
          )}
          {!isFirstStep &&
            actualSchema.navigationButtons &&
            actualSchema.navigationButtons[0] && (
              <Button onClick={handleGoToPreviousStep}>
                <i
                  style={{ marginRight: 10 }}
                  className="fas fa-angle-left fa-2x"
                ></i>
                {actualSchema.navigationButtons[0]}
              </Button>
            )}
          {!isLastStep &&
            actualSchema.navigationButtons &&
            actualSchema.navigationButtons[1] && (
              <Button
                onClick={() => handleGoToNextStep(nextStepValue, stepName)}
              >
                {actualSchema.navigationButtons[1]}
                <i
                  style={{ marginLeft: 10 }}
                  className="fas fa-angle-right fa-2x"
                ></i>
              </Button>
            )}
          {isLastStep && (
            <Button
              onClick={() => {
                goToNamedStep("company-page");
              }}
            >
              Return to Company
              <i
                style={{ marginLeft: 10 }}
                className="fas fa-angle-right fa-2x"
              ></i>
            </Button>
          )}
        </div>
      </Container>
      <Container id="step-guide" className="step-container">
        <Container id="step-guide-inner">
          <FontAwesomeIcon icon={actualSchema.icon} className="sidebar-icon" />
          <div className="step-title">{actualSchema.title}</div>
          {actualSchema && actualSchema.sidebarDescription
            ? actualSchema.sidebarDescription
            : null}
        </Container>
      </Container>
    </Box>
  );
}
