import { faPencilAlt, faThList } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

export const servicesSchema = {
  "edit-program-services": {
    type: "object",
    title: "Edit Program - Program Services",
    properties: {
      services: {
        type: "array",
        items: {
          type: "object",
          properties: {
            serviceName: {
              type: "string",
              title: "service name",
              default: "new service"
            },
            roundNumber: { type: "number", default: 1 },
            priceTableId: { type: "string", default: "" },
            cutOffDate: { type: "string", default: "" }
          }
        }
      }
    },
    icon: faPencilAlt,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          How Many Applications/Services Are Performed?
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Add Servies/Applications/Rounds to your program and attach price table
          so Lawnbot can determine pricing per service. Cut off dates tell
          Lawnbot when services are no longer available for purchase.
          <br />
          <ul style={{ textAlign: "left", marginLeft: "10%" }}>
            <li>
              <u>Service Name</u>- A label representing this
              application/round/service (e.g. &apos;round 1&apos; )
            </li>
            <li>
              <u>Round Number</u>- A number from one to the number of services
              in this program{" "}
            </li>
            <li>
              <u>Price Table</u>- *required - Select the price table you which
              to attach to this service{" "}
            </li>
            <li>
              <u>Cut-Off Date</u>- The date which you no longer wish this
              service to be offered{" "}
            </li>
          </ul>
        </Typography>
      </div>
    )
  }
};
