import { faBox, faImages } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

export const brandingSchema = {
  "branding-start": {
    title: "Company Branding",
    type: "object",
    required: ["title"],
    properties: {
      tagLine: { type: "string", title: "Tag Line" },
      valueAddDescription: { type: "string", title: "Business Description" }
    },
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Make Lawnbot an Extension of Your Brand!
        </Typography>
        <hr />

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Lawnbot is a &apos;white-label&apos; solution which is custom branded
          to your company and integrated into your current website, social media
          sites, and email campaigns. In this section, we are going to upload
          your logos, company descriptions, and taglines to make your future
          customers feel like your Lawnbot is an extension of your brand.
        </Typography>
        <ul className="step-description-ul">
          <li>
            <u>Tag Line</u> - A short snappy slogan or saying about your
            business (e.g. Service with a Smile, We make you lawn mean and green
            )
          </li>
          <li>
            <u>Business Description</u> - A Description of your business which
            will be displayed in the chatbot that speaks to why customers should
            choose your company.
          </li>
        </ul>
      </div>
    ),

    icon: faBox
  },
  "branding-images": {
    title: "Branding Images",
    type: "object",
    properties: {
      companyLogo: { type: "string", title: "Company Logo" },
      companyAvatar: { type: "string", title: "Company Avatar" },
      valueAddImage: { type: "string", title: "Value Add Image" },
      aboutUsAssetUrl: { type: "string", title: "About Us" }
    },
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Let&apos;s upload the images used in Lawnbot
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          {`Clicking on the 'Choose File' or already uploaded images will open a cropping tool allowing you to resize your image to the ratio required for each image.`}{" "}
          <b>WARNING!</b>{" "}
          {`Be sure your images are not too large or you won't be able to upload them.`}
        </Typography>
        <ul className="step-description-ul">
          <li>
            <u>Company Logo</u> - This image will be used for the header of your
            Lawnbot. This image should be high-quality so the Lawnbot team
            create an awesome graphic. This image should be four times the width
            than the height (i.e a rectangular image/4:1 ratio)
          </li>
          <li>
            <u>Company Avatar</u> - This image is used for the &apos;talking
            head&apos; and should be equal in height and width (i.e a square
            image or 1:1 ratio)
          </li>
          <li>
            <u>Value Add Image</u> - This image will be displayed in the
            customers &apos;shopping cart page&apos;. This image should help
            instill confidence and professionalism of your company. Most of our
            clients use pictures of their team and/or results of their work.
            This image should be four times the width than the height (i.e a
            rectangular image/4:1 ratio)
          </li>
          <li>
            <u>About Us Image</u> - This image is displayed during the chat flow
            and many of our clients use the &apos;Value Add Image&apos;, however
            you may use any image that you like. This image should be four times
            the width than the height (i.e a rectangular image/4:1 ratio)
          </li>
        </ul>
      </div>
    ),
    icon: faImages
  }
};
