import React from "react";
import { Button, TableCell, TableRow } from "@material-ui/core";

function removeFromPriceTables(priceTables, id) {
  delete priceTables[id];
  return priceTables;
}

export function PriceTable({
  priceTable,
  handleChange,
  formData,
  setEditingPriceTableId,
  handleGoToNextStep
}) {
  return (
    <TableRow className={"price-table"}>
      <TableCell>{priceTable.name}</TableCell>
      <TableCell>{priceTable.year}</TableCell>
      <TableCell>{priceTable.prePayDiscount}</TableCell>
      <TableCell align="right">
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            setEditingPriceTableId(priceTable.id);
            handleGoToNextStep("edit-price-table", "edit-upload-price-table");
          }}
        >
          Edit
        </Button>
      </TableCell>
      <TableCell>
        <Button
          color="secondary"
          onClick={() => {
            handleChange(removeFromPriceTables({ ...formData }, priceTable.id));
          }}
        >
          X
        </Button>
      </TableCell>
    </TableRow>
  );
}
