import React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import states from "./us-state.js";
export function StateField({ formData, onChange }) {
  return (
    <FormControl>
      <InputLabel id="customerState">State</InputLabel>
      <Select
        labelid="customerState"
        id="state-select"
        value={formData}
        error={!formData}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        style={{ minWidth: 50 }}
        errorMessages={["this field is required"]}
        validators={["required"]}
      >
        {states.map((i) => {
          const key = "menu-item-" + Math.floor(Math.random() * 100000);
          return (
            <MenuItem key={key} value={i}>
              {i}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
