const lawnbotAPIEndpoint = "https://api.lawnbot.biz/intergration/real-green";
import { v4 as uuidv4 } from "uuid";
export const getDefaultForm = () => {
  return {
    bot: { id: uuidv4() },
    serviceQuestions: [
      {
        type: "default",
        question: "Do you have a locked gate?",
        options: [{ text: "yes" }, { text: "no" }]
      },
      {
        type: "default",
        question: "Would you like to be emailed or texted prior to each visit?",
        options: [{ text: "Email" }, { text: "Text" }, { text: "Both" }]
      },
      {
        type: "default",
        question: "Do you have a dog?",
        options: [{ text: "yes" }, { text: "no" }]
      }
    ],
    objections: {
      price: {
        type: "company-objection-price",
        question:
          "I understand! We may not be the cheapest but with us you get a family-owned and operated company, that will give you the personal attention a beautiful lawn requires...."
      },
      people: {
        type: "company-objection-people",
        question:
          "We a local family-owned business right here in your area. With us you get the hands-on personal touch that gets lost when dealing with large national companies!"
      },
      product: {
        type: "company-objection-product",
        question:
          "We use the newest technology to decrease the use of pesticides while improving the overall look and health of your lawn. We strongly believe the biggest obstacle in our industry is having uneducated customers. That’s why our goal is to build a company that revolutionizes the green industry by bridging the gap between customers and company."
      }
    }
  };
};
export const getCompanyDiscountCodes = async (apiKey) => {
  try {
    const code = await fetch(`${lawnbotAPIEndpoint}/discounts`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return code.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyPrograms = async (apiKey) => {
  try {
    const programs = await fetch(`${lawnbotAPIEndpoint}/programs`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return programs.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

function updateFormDataObject(
  key,
  prevData,
  formDataKey,
  editingKey,
  propertyToEdit,
  newFormData,
  isArray
) {
  const isEditing = editingKey || editingKey === 0;
  let response;
  if (isEditing) {
    if (isArray) {
      response = [...prevData[formDataKey]];
      response[editingKey] = {
        ...prevData[formDataKey][editingKey],
        ...newFormData
      };
    } else {
      response = {
        ...(prevData[formDataKey] && prevData[formDataKey]
          ? prevData[formDataKey]
          : {}),
        [editingKey]: {
          ...prevData[formDataKey][editingKey],
          ...newFormData
        }
      };
    }
  } else {
    response = isArray ? [...newFormData] : { ...newFormData };
  }
  return response;
}

export function getFormToPassDown(
  formData,
  formDataKey,
  editingKey,
  propertyToEdit
) {
  return formData && formDataKey && formData[formDataKey]
    ? !propertyToEdit
      ? formData[formDataKey]
      : editingKey || editingKey === 0
      ? formData[formDataKey][editingKey]
      : {}
    : {};
}

export function getPreviousStep(key, formData) {
  return formData["previousStepBookmarks"]
    ? formData["previousStepBookmarks"][key]
    : "";
}

export function setPreviousStep(key, setFormData) {
  return (newPrevStep) =>
    setFormData((prevData) => {
      return {
        ...prevData,
        previousStepBookmarks: {
          ...(prevData["previousStepBookmarks"]
            ? prevData["previousStepBookmarks"]
            : {}),
          [key]: newPrevStep
        }
      };
    });
}
export function handleStepFormChange(
  setFormData,
  formDataKey,
  key,
  editingKey,
  propertyToEdit,
  isArray,
  saveData,
  editingKeys
) {
  let newData = {};
  return function (newFormData) {
    setFormData((prevData) => {
      newData = {
        ...prevData,
        [formDataKey]: updateFormDataObject(
          key,
          prevData,
          formDataKey,
          editingKey,
          propertyToEdit,
          newFormData,
          isArray
        )
      };
      return newData;
    });
    saveData(newData, key, editingKeys);
  };
}

export function setRecordedNextStep(setFormData) {
  return (key, recordedNextStep) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        nextStepBookmarks: {
          ...(prevData["nextStepBookmarks"]
            ? prevData["nextStepBookmarks"]
            : {}),
          [recordedNextStep]: key
        }
      };
    });
  };
}

export function getRecordedNextStep(formData, key) {
  return formData["nextStepBookmarks"]
    ? formData["nextStepBookmarks"][key]
    : "";
}

export function saveCurrentStep(stepName, setFullData) {
  if (!stepName.includes("edit")) {
    setFullData((prevData) => {
      return { ...prevData, userIsOnStep: stepName };
    });
  }
}

export const getCompanyEmployees = async (apiKey) => {
  try {
    const employees = await fetch(`${lawnbotAPIEndpoint}/employees`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return employees.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyRoutes = async (apiKey) => {
  try {
    const routes = await fetch(`${lawnbotAPIEndpoint}/routes`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return routes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyMarketingCodes = async (apiKey) => {
  try {
    const codes = await fetch(`${lawnbotAPIEndpoint}/marketing-sources`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return codes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyCallStatuses = async (apiKey) => {
  try {
    const codes = await fetch(`${lawnbotAPIEndpoint}/call-statuses`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return codes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyCallActionReasons = async (apiKey) => {
  try {
    const codes = await fetch(`${lawnbotAPIEndpoint}/call-action-reasons`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return codes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanies = async (apiKey) => {
  try {
    const codes = await fetch(`${lawnbotAPIEndpoint}/companies`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        apiKey: apiKey,
        host: "api.realgreen.com"
      }
    });
    return codes.json();
  } catch (e) {
    console.log(e);
    return [];
  }
};
