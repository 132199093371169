import React from "react";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Typography
} from "@material-ui/core";
import { addNewPricingTable } from "./pricing-tables-helpers.js";
import { PriceTable } from "./price-table/PriceTable.jsx";
import { GetOnboardingData } from "../../../context/onboarding-data.js";
export function PricingTables({ formData, onChange, companyId }) {
  const { goToNextStepFunc, setEditingPriceTableId } = GetOnboardingData();
  const pricingTables = Object.keys(formData && formData ? formData : {}).map(
    (id) => {
      return (
        <PriceTable
          key={`program-row-${id}`}
          priceTable={formData[id]}
          handleChange={onChange}
          formData={formData}
          setEditingPriceTableId={setEditingPriceTableId}
          handleGoToNextStep={goToNextStepFunc}
        />
      );
    }
  );
  return (
    <div>
      <Box style={{ marginBottom: 10 }}>
        <Typography variant={"h5"}>Edit Program - Price Tables</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <Container>
        <Button
          style={{
            float: "right",
            position: "relative",
            marginBottom: 15
          }}
          variant="text"
          onClick={addNewPricingTable(
            onChange,
            formData,
            companyId,
            setEditingPriceTableId,
            goToNextStepFunc
          )}
        >
          <i
            style={{ color: "green", marginRight: 5 }}
            className="fas fa-plus-circle"
          ></i>
          Create Price Table
        </Button>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>PrePay Discount</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{pricingTables}</TableBody>
        </Table>
      </Container>
    </div>
  );
}
