import { WelcomeScreen } from "../../step-component/custom-steps/welcome/welcome-screen.jsx";

export function getWelcomeUI() {
  return {
    "welcome-page": () => {
      return {
        "ui:FieldTemplate": WelcomeScreen
      };
    }
  };
}
