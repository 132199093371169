import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify, { Auth } from "aws-amplify";
import awsExports from "./aws-exports";
const custom = [
  {
    name: "dashboardapi",
    endpoint: "https://api.test.lawnbot.biz",
    // endpoint: "http://localhost:3006",
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      };
    }
  }
];
awsExports.aws_cloud_logic_custom = custom;

Amplify.configure(awsExports);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
