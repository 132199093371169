import React from "react";
import { Button, TableCell, TableRow } from "@material-ui/core";
import { CheckCircle, BlockRounded } from "@material-ui/icons";
import { GetOnboardingData } from "../../../context/onboarding-data";
export function OfferTableRow({
  offer,
  deleteOffer,
  setEditingOfferId,
  handleGoToNextStep
}) {
  const { editingKeys = {} } = GetOnboardingData();
  return (
    <TableRow className={"offer-table-row"}>
      <TableCell>{offer.name}</TableCell>
      <TableCell>{offer.startDate}</TableCell>
      <TableCell>{offer.endDate}</TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {offer.active ? <CheckCircle /> : <BlockRounded />}
      </TableCell>
      <TableCell align="right">
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            setEditingOfferId(offer.id);
            handleGoToNextStep("edit-offer", "edit-offers-table", {
              ...editingKeys,
              editingOfferId: offer.id
            });
          }}
        >
          Edit
        </Button>
      </TableCell>
      <TableCell>
        <Button
          color="secondary"
          onClick={() => {
            deleteOffer(offer.id);
          }}
        >
          X
        </Button>
      </TableCell>
    </TableRow>
  );
}
