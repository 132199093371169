import { faBuilding, faTag } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

export const companySchema = {
  "company-page": {
    type: "object",
    title: "Company Information",
    properties: {
      companyName: {
        type: "string",
        title: "Company Name",
        default: ""
      },
      prePayAvailable: { type: "boolean", default: true },
      autoPayAvailable: { type: "boolean", default: true },
      autoPayAlternativeLabel: { type: "string", default: "Auto-Pay" },
      autoPayPriceMethod: {
        type: "string",
        default: "first-application-payment"
      },
      autoPayMonthlyPricePeriod: { type: "number", default: 12 },
      customerName: { type: "string", title: "Customer Name", default: "" },
      customerEmail: { type: "string", title: "Customer Email", default: "" },
      customerPhone: { type: "string", title: "Phone Number", default: "" },
      customerAddress: { type: "string", title: "Address", default: "" },
      customerCity: { type: "string", title: "City", default: "" },
      customerState: { type: "string", title: "State", default: "" },
      customerZip: { type: "string", title: "Zip Code", default: "" },
      currency: { type: "string", title: "Currency", default: "US Dollar" },
      maxQuotableSize: {
        type: "number",
        title: "Max Quotable Lot Size (sq ft)",
        default: 0
      },
      minQuotableSize: {
        type: "number",
        title: "Min Quotable Lot Size (sq ft)",
        default: 0
      },
      termsOfService: {
        type: "string",
        default: "",
        title: ""
      }
    },
    icon: faBuilding,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          We&apos;ve already filled in your information which you provided to
          our team. Please review and make changes if needed.
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        ></Typography>
        <ul className="step-description-ul">
          <li>
            <u>Company Name</u> - Name of your company
          </li>
          <li>
            {" "}
            <u>Customer Name</u> - Primary contact for your company{" "}
          </li>
          <li>
            <u>Customer Email</u> - Email addresses to receive notifications of
            Leads/Sale. Seperate multiple email addresses with a comma (e.g.
            tom@lawn.com,sue@lawn.com)
          </li>
          <li>
            <u>Phone Number</u> - Primary contact phone number
          </li>
          <li>
            {" "}
            <u>Address / City / State / Zip Code</u> - Address information for
            your company
          </li>
        </ul>
      </div>
    )
  },
  "company-pricing": {
    type: "object",
    title: "Company Pricing",
    icon: faTag,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Let&apos;s Setup Some Basic Pricing Parameters.
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        ></Typography>
        <ul className="step-description-ul">
          <li>
            {" "}
            <u>Currency</u> - Please select your currency from the list{" "}
          </li>
          <li>
            <u>Min Quotable Lot Size</u> - What is the smallest lot size you
            wish to quote in your Lawnbot? (this prevents Lawnbot from quoting
            jobs which won&apos;t be profitable for your company )
          </li>
          <li>
            <u>Max Quotable Lot Size</u> - What is the largest lot size you wish
            to quote in your Lawnbot? (this prevents Lawnbot from quoting large
            jobs which may be too complex and needs further information )
          </li>
          <li>
            {" "}
            <u>Terms of Service</u> - Enter any terms of service which you have
            for your business. This will be displayed during checkout.
          </li>
        </ul>
      </div>
    )
  },
  "real-green-settings": {
    type: "object",
    title: "Real Green Settings",
    properties: {
      crmCompanyId: {
        type: "string",
        title: "Company Id",
        default: ""
      },
      lawnbotEmployee: { type: "string", title: "Lawnbot Employee" },
      crmRoute: { type: "string", title: "Route", default: "" },
      marketingSource: {
        type: "string",
        label: "Marketing Source",
        default: ""
      },
      callStatusCode: {
        type: "string",
        label: "Sale Call Status",
        default: ""
      },
      callActionReason: {
        type: "string",
        label: "Sale Call Action Reason",
        default: ""
      },
      crmPortalUrl: {
        type: "string",
        title: "RealGreen Customer Portal",
        default: ""
      },
      crmNewRecordStatusScode: {
        type: "string",
        title: "New Record Status",
        default: "0"
      },
      crmQuoteStatusCode: {
        type: "string",
        title: "Quote Received Status",
        default: "1"
      },
      crmSaleStatusCode: {
        type: "string",
        title: "Sale Made Status",
        default: "9"
      }
    },
    icon: faBuilding,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Update your Real Green Settings.
        </Typography>
        <hr />
        <ul className="step-description-ul">
          <li>
            <u>Company Id (Not Required)</u> - Real Green will automatically
            detect which Branch/Company to insert customers into based on zip
            code. However, If you require new customers records to be inserted
            into a particular branch then use this field.
          </li>
          <li>
            <u>Lawnbot Employee</u> - Select an employee for Lawnbot records to
            be inserted by. Recommendation: Create a new employee called
            &quot;Lawnbot&quot; in your Service Assistant and then select them
            here. You may need to refresh this page once you have created the
            new employee and added any other options within Service Assistant
            before they will display here.
          </li>
          <li>
            <u>Route (Not Required)</u> - Real Green will automatically detect
            which route to insert customers into based on address/zip code.
            However, If you require new customers records to be inserted into a
            particular route then use this field.
          </li>
          <li>
            <u>Marketing Source</u> - Select a marketing source for Lawnbot
            records to be inserted by.
          </li>
          <li>
            <u>Sales Call Status</u> - Select a call status for Lawnbot records
            to be inserted by.
          </li>
          <li>
            <u>Sales Call Action Reason</u> - Select an action reason for
            Lawnbot records to be inserted by.
          </li>
          <li>
            <u>Real Green Customer Portal</u> - Enter the customer portal URL
            for you Service Assistant. We will redirect any customer that we
            find in status 8/9 so we don&apos;t quote your current customers.
          </li>
          <li>
            <u>Record Status</u>
            <ul>
              <li style={{ marginBottom: 0 }}>
                New Record - HAVEN&apos;T seen quote
              </li>
              <li style={{ marginBottom: 0 }}>
                Quote Received - HAVE seen quote
              </li>
              <li style={{ marginBottom: 0 }}>
                Sale Made - customer PURCHASED
              </li>
            </ul>
          </li>
        </ul>
      </div>
    )
  },
  "create-test-customer": {
    type: "object",
    title: "Test Customer",
    icon: faBuilding,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Create Your Test Customer.
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          This test customer will be used to test the pricing of your Real Green
          programs later on.
        </Typography>
      </div>
    )
  }
};
