import React from "react";
import { TextField, Box, Typography } from "@material-ui/core";
import { StateField } from "./individual-fields/StateField.jsx";
import "./company.css";
export function GeneralCustomerInfo({ formData, onChange }) {
  function updateCompany(field, value) {
    onChange({ ...formData, [field]: value });
  }
  return (
    <div>
      <Box>
        <Typography variant={"h5"}>Company Information</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <div id="edit-company-main">
        <TextField
          id="company-name"
          style={{ display: "block" }}
          label="Company Name"
          type="string"
          error={!formData.companyName}
          value={formData.companyName}
          onChange={(e) => {
            updateCompany("companyName", e.target.value);
          }}
          InputProps={{
            style: {
              width: "80%"
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="customer-name"
          style={{ display: "block" }}
          label="Customer Name"
          type="string"
          error={!formData.customerName}
          value={formData.customerName}
          onChange={(e) => {
            updateCompany("customerName", e.target.value);
          }}
          InputProps={{
            style: {
              width: "80%"
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <div className="company-info-row">
          <TextField
            id="email"
            label="Customer Email"
            type="email"
            error={!formData.customerEmail}
            style={{ width: "50%" }}
            value={formData.customerEmail}
            onChange={(e) => {
              updateCompany("customerEmail", e.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="phone-number"
            label="Phone Number"
            error={!formData.customerPhone}
            type="string"
            style={{ width: "50%" }}
            value={formData.customerPhone}
            onChange={(e) => {
              updateCompany("customerPhone", e.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <TextField
          id="address"
          label="Address"
          type="string"
          error={!formData.customerAddress}
          value={formData.customerAddress}
          onChange={(e) => {
            updateCompany("customerAddress", e.target.value);
          }}
          InputProps={{
            style: {
              width: "80%"
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />

        <div className="company-info-row">
          <TextField
            id="city "
            label="City"
            type="string"
            error={!formData.customerCity}
            value={formData.customerCity}
            style={{ marginRight: 20 }}
            onChange={(e) => {
              updateCompany("customerCity", e.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <StateField
            formData={formData.customerState}
            onChange={(customerState) =>
              updateCompany("customerState", customerState)
            }
          />
          <TextField
            id="zip"
            label="Zip Code"
            type="string"
            error={!formData.customerZip}
            value={formData.customerZip}
            style={{ marginLeft: 20 }}
            onChange={(e) => {
              updateCompany("customerZip", e.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      </div>
    </div>
  );
}
