import { Offers, EditOffer } from "../../step-component/custom-steps/offers";

export function getOfferUI() {
  return {
    "edit-offers-table": () => {
      return {
        "ui:FieldTemplate": Offers
      };
    },
    "edit-offer": () => {
      return {
        "ui:FieldTemplate": EditOffer
      };
    }
  };
}
