import { faBox, faImages } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";
import { stringify, v4 as uuidv4 } from "uuid";

export const botSchema = {
  "bot-settings": {
    title: "Bot Settings",
    type: "object",
    required: ["title"],
    properties: {
      id: { type: stringify, default: uuidv4() },
      zipCodes: { type: "string", title: "Serviced Zip Codes", default: "" },
      willRedirect: { type: "boolean", default: false },
      serviceTax: { type: "number", title: "Service Tax", default: 0 },
      redirectUrl: { type: "string", default: "https://lawnbot.biz" },
      dontTaxZipCodes: {
        type: "string",
        title: "Don't Tax Zip Codes",
        default: ""
      },
      greeting: { type: "string", title: "Greeting", default: "" },
      salutation: {
        type: "string",
        title: "How would you like to greet the customer?",
        default: ""
      },
      lookupZipCodeTax: {
        type: "boolean",
        title: "Lookup Tax From ZipCode",
        default: false
      }
    },
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Update Behavioral Settings for Your Bot.
        </Typography>
        <hr />
        {`On this page, you can update certain settings that will affect your bot's behavior when dealing with potential customers.`}
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        ></Typography>
        <ul className="step-description-ul">
          <li>
            <u>How Do You Want To Greet The User?</u> -{" "}
            {`After your bot learns
            the customers info, this will set how the bot addresses them.
            Example: Hello, John. The 'Hello' being what you entered into this
            field. `}
          </li>

          <li>
            <u>Lookup Zip Code Tax? (Non-RG Only)</u> -{" "}
            {`This box will cause your bot to automatically tax services based on the zip code entered by your customers.`}
          </li>
          <li>
            <u>Service Tax (Non-RG Only)</u> - We lookup tax rates by zip codes,
            however if you don&apos;t want to charge tax then enter a zero into
            this field. This field is ignored if &quot;Lookup Zip Code Tax&quot;
            is checked.
          </li>
          <li>
            <u>Redirect After Sale?</u> -{" "}
            {`Checks whether the bot will redirect the customer after completing a sale`}
          </li>
          <li>
            <u>Redirect URL</u> -{" "}
            {`The website customers will be redirected to if "Redirect After Sale?" is true.`}
          </li>
          <li>
            <u>Serviced Zip Codes</u> - Your bot will only automatically quote
            addresses within zip codes that you specify. You will still get lead
            information for zip codes you do not list so you can contact the
            potential customer if desired, but the potential customer will not
            receive an automatic quote.
          </li>
          <li>
            <u>{`Don't Tax Zip Codes`}</u> - Use this field if there are certain
            zip codes where you want to ignore taxes during pricing calculations
            while building a quote.
          </li>
        </ul>
      </div>
    ),

    icon: faBox
  }
};
