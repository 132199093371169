import React, { Fragment } from "react";
import { Button, TableCell, TableRow } from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data";
function removeFromPrograms(programs, id) {
  delete programs[id];
  return programs;
}

function checkForErrors(program) {
  if (!program.programType) {
    return true;
  }
  if (program.useRealGreenPricingEngine && !program.crmProgramCode) {
    return true;
  } else if (!program.useRealGreenPricingEngine) {
    if (
      !program.useRealGreenPricingEngine &&
      (!program.services || !program.services.length)
    ) {
      return true;
    } else {
      // check program services
      program.services.forEach((service) => {
        if (!service.priceTableId) {
          return true;
        }
        if (!service.cutOffDate) {
          return true;
        }
      });
    }
  }
  return false;
}

export function ProgramRow({
  program,
  handleChange,
  formData,
  setEditingProgramId,
  handleGoToNextStep
}) {
  const { editingKeys = {} } = GetOnboardingData();
  const errors = checkForErrors(program);
  return (
    <Fragment>
      {errors ? <i className="program-error fas fa-arrow-right"></i> : null}
      <TableRow
        style={{
          borderLeft: errors ? "1.5px solid red" : "none"
        }}
        className={"program-row"}
      >
        <TableCell>{program.name}</TableCell>
        <TableCell>{program.description}</TableCell>
        <TableCell>{program.programType}</TableCell>
        <TableCell align="right">
          <Button
            color="primary"
            variant="text"
            onClick={async () => {
              setEditingProgramId(program.id);
              handleGoToNextStep("edit-program", "program-page", {
                ...editingKeys,
                editingProgramId: program.id
              });
            }}
          >
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button
            color="secondary"
            onClick={() => {
              const programs = removeFromPrograms({ ...formData }, program.id);
              handleChange(programs);
            }}
          >
            X
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
