import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import "./bot.css";
import { GetOnboardingData } from "../../../context/onboarding-data";

function checkZips(value, rgZipCodes, setZipError) {
  if (value && value.length && rgZipCodes && rgZipCodes.length) {
    let valueArray = value.split(/[\n,\s]/g).map((i) => i.trim());
    valueArray = valueArray.filter((zip) => zip);
    const rgCodeArray = rgZipCodes.map((code) => code.zip);
    const notAllowedArray = valueArray.filter((x) => !rgCodeArray.includes(x));
    if (notAllowedArray.length) {
      let errorString = "";
      notAllowedArray.forEach((zip, index) => {
        errorString += zip.toString();
        if (index === notAllowedArray.length - 1) {
          errorString += ".";
        } else {
          errorString += ", ";
        }
      });
      errorString +=
        " These zip codes are not in your SA5 and are should not be added to your bot zips.";
      setZipError(errorString);
    } else {
      setZipError("");
    }
  }
}
export function BotSettings({ formData, onChange }) {
  const [botSettings, setBotSettings] = useState(formData);
  const { rgZipCodes, isRealGreen } = GetOnboardingData();
  const [zipError, setZipError] = useState("");
  function updateBotSettings(field, value) {
    if (field === "zipCodes" && isRealGreen) {
      checkZips(value, rgZipCodes, setZipError);
    }

    setBotSettings((prev) => {
      return { ...prev, [field]: value };
    });
    onChange({ ...formData, [field]: value });
  }

  useEffect(() => {
    setBotSettings(formData);
  }, [formData]);

  useEffect(() => {
    if (rgZipCodes && rgZipCodes.length && !botSettings.zipCodes) {
      let zips = "";
      rgZipCodes.forEach((code) => (zips += code.zip.toString() + "\n"));
      setBotSettings((prev) => {
        return { ...prev, zipCodes: zips };
      });
      onChange({ ...formData, zipCodes: zips });
    }
  }, [rgZipCodes, formData, onChange, botSettings.zipCodes]);

  return botSettings ? (
    <div>
      <Box>
        <Typography variant={"h5"}>Bot Settings</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <div id="edit-bot-main">
        <TextField
          id="salutation"
          style={{ display: "block" }}
          label="How Do You Want To Greet The User?"
          type="string"
          value={botSettings.salutation}
          onChange={(e) => {
            updateBotSettings("salutation", e.target.value);
            setBotSettings;
          }}
          InputProps={{
            style: {
              width: "80%"
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />

        <div
          style={{
            marginTop: 30,
            marginBottom: 30,
            display: "flex",
            alignItems: "baseline"
          }}
        >
          {!isRealGreen && (
            <FormControlLabel
              label="Lookup Zip Code Tax?"
              style={{ marginRight: 50 }}
              control={
                <Checkbox
                  checked={formData.lookupZipCodeTax}
                  onChange={(e) => {
                    updateBotSettings("lookupZipCodeTax", e.target.checked);
                  }}
                />
              }
            />
          )}
          {!isRealGreen && (
            <TextField
              id="service-tax"
              label="Service Tax (%)"
              disabled={formData.lookupZipCodeTax}
              type="number"
              value={formData.serviceTax}
              style={{ marginRight: 20 }}
              onChange={(e) => {
                updateBotSettings(
                  "serviceTax",
                  e.target.value ? parseFloat(e.target.value) : ""
                );
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
        </div>
        <div>
          {" "}
          <FormControlLabel
            label="Redirect After Sale?"
            control={
              <Checkbox
                checked={formData.willRedirect}
                onChange={(e) => {
                  updateBotSettings("willRedirect", e.target.checked);
                }}
              />
            }
          />
          <TextField
            id="redirect-url"
            label="Redirect URL"
            type="text"
            style={{ width: "100%", marginTop: 15 }}
            value={botSettings.redirectUrl}
            onChange={(e) => {
              updateBotSettings("redirectUrl", e.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div id="zip-codes-container">
          <TextField
            id="serviced-zip-codes"
            className="text-field"
            label="Serviced Zip Codes"
            type="text"
            multiline
            error={zipError}
            helperText={zipError}
            value={botSettings.zipCodes}
            rows={15}
            onChange={(e) => {
              updateBotSettings("zipCodes", e.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="dont-tax"
            className="text-field"
            label="Don't Tax Zip Codes"
            type="text"
            multiline
            value={botSettings.dontTaxZipCodes}
            rows={15}
            onChange={(e) => {
              updateBotSettings("dontTaxZipCodes", e.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      </div>
    </div>
  ) : null;
}
