import React, { useState, useEffect } from "react";
import { GetOnboardingData } from "../../../context/onboarding-data";
import {
  TextField,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { testNonRTPPricing } from "./price-testing-helpers";
export function TestPricing({ isActive }) {
  const {
    programs,
    priceTables,
    offers,
    company,
    testCustomerId,
    botSettings
  } = GetOnboardingData();
  const [size, setSize] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({ id: "" });
  const [pricing, setPricing] = useState(null);
  const [postalCode, setPostalCode] = useState("");
  const isRTP = selectedProgram
    ? selectedProgram.useRealGreenPricingEngine
    : false;
  useEffect(() => {
    if (programs && isActive) {
      setSelectedProgram(Object.values(programs)[0]);
    }
  }, [programs, isActive]);
  return (
    <div>
      <Box style={{ marginBottom: 30 }}>
        <Typography variant={"h5"}>Test Pricing</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <div
        style={{
          marginBottom: 30,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "left"
        }}
      >
        <div>
          {" "}
          <InputLabel id="demo-simple-select-label">
            Select Program to Test
          </InputLabel>
          <Select
            id="programId"
            style={{ marginTop: 14, width: 250 }}
            value={selectedProgram.id}
            label={"Select Program to Test"}
            onChange={(e) => setSelectedProgram(programs[e.target.value])}
            validators={["required"]}
          >
            {Object.values(programs).map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={i.id}>
                  {i.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        {isRTP && (
          <TextField
            id="customerId"
            label="RG Customer ID"
            type="string"
            disabled={true}
            style={{ width: "40%" }}
            value={testCustomerId}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        {!isRTP && botSettings.lookupZipCodeTax && (
          <TextField
            id="postalCode"
            label="Postal Code"
            type="string"
            style={{ width: "40%" }}
            onChange={(e) => {
              setPostalCode(e.target.value);
            }}
            value={postalCode}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
      </div>

      <div style={{ display: "flex", marginBottom: 40 }}>
        <TextField
          id="square-footage"
          label="Set Square Footage"
          type="number"
          style={{ width: "40%" }}
          value={size}
          onChange={(e) => {
            setSize(e.target.value ? parseInt(e.target.value) : "");
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          disabled={
            !selectedProgram ||
            !selectedProgram.id ||
            !size ||
            (isRTP && !testCustomerId) ||
            (!isRTP && botSettings.lookupZipCodeTax && !postalCode)
          }
          style={{ marginLeft: 40 }}
          variant="contained"
          color="primary"
          onClick={async (e) => {
            setLoading(true);
            setError(false);
            const results = await testNonRTPPricing(
              size,
              selectedProgram,
              priceTables,
              selectedProgram.usingOffers ? offers : {},
              isRTP,
              testCustomerId,
              company,
              botSettings,
              postalCode
            );
            if (results) {
              typeof results === "string"
                ? setError(results)
                : setPricing(results);
            }
            setLoading(false);
          }}
        >
          Test
        </Button>
      </div>
      <hr />
      <div style={{ marginTop: 40 }}>
        {" "}
        {loading ? (
          <div
            style={{
              height: "100px",
              textAlign: "center",
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto"
            }}
          >
            <CircularProgress style={{ margin: "auto" }} />
            <span>Gathering Pricing Data...</span>
          </div>
        ) : !loading && error ? (
          <div>{error} </div>
        ) : pricing && pricing.applicationPricing && pricing.prePayPricing ? (
          <div style={{ textAlign: "left" }}>
            <div style={{ borderBottom: "1px solid black", marginBottom: 10 }}>
              <Typography variant="h6">Per Application</Typography>
              {pricing.applicationPricing.display}
            </div>
            <div>
              <Typography variant="h6">Pre-Pay</Typography>
              {pricing.prePayPricing.display}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
