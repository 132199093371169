import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  CircularProgress
} from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data";
import "./company.css";
const statuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
  return { value: `${i}`, label: `${i}` };
});

export function RealGreenPage({ onChange, formData }) {
  function updateRealGreenSettings(field, value) {
    onChange({ ...formData, [field]: value });
  }
  useEffect(() => {
    if (formData) {
      onChange({ ...formData });
    }
  }, [formData, onChange]);
  const {
    employees,
    marketingCodes,
    callReasons,
    callStatuses,
    companies,
    routes,
    loadingRG
  } = GetOnboardingData();
  return (
    <div>
      <Box>
        <Typography variant={"h5"}>Real Green Settings</Typography>
        <hr className="MuiDivider-root" />
      </Box>{" "}
      {loadingRG ? (
        <div
          style={{
            height: "100px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto"
          }}
        >
          <CircularProgress style={{ margin: "auto" }} />
          <span>Still downloading RG Data. Please Wait...</span>
        </div>
      ) : (
        <Fragment>
          <div className={"rg-settings-row"}>
            <FormControl>
              <InputLabel id="companyId">Company Id</InputLabel>
              <Select
                labelid="companyId"
                label="Company Id"
                value={formData.crmCompanyId || null}
                style={{ minWidth: 200 }}
                placeholder="Select Company"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings(
                    "crmCompanyId",
                    e.target.value.toString()
                  );
                }}
              >
                <MenuItem key={"defaultcompid"} value={-1}>
                  No Company Selected
                </MenuItem>
                {companies.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem key={i.comp_id + "adfaddw"} value={i.comp_id}>
                      {i.compname + " ( " + i.comp_id + " )"}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="employee">Lawnbot Employee</InputLabel>
              <Select
                labelid="employee"
                label="Lawnbot Employee"
                value={formData.lawnbotEmployee || null}
                style={{ minWidth: 200 }}
                placeholder="Lawnbot"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings("lawnbotEmployee", e.target.value);
                }}
              >
                {employees.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem
                      key={i.emp_id.trim() + "adfaddw"}
                      value={i.emp_id.trim()}
                    >
                      {i.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={"rg-settings-row"}>
            <FormControl>
              <InputLabel id="route">Route</InputLabel>
              <Select
                labelid="route"
                label="Route"
                value={formData.crmRoute || null}
                style={{ minWidth: 200 }}
                placeholder="Lawnbot"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings("crmRoute", e.target.value);
                }}
              >
                {routes.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem
                      key={i.emp_id.trim() + "adfaddw"}
                      value={i.route.trim()}
                    >
                      {i.route_desc}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="marketing-source">Marketing Source</InputLabel>
              <Select
                labelid="marketing-source"
                label="Marketing Source"
                value={formData.marketingSource || null}
                style={{ minWidth: 200 }}
                placeholder="Chatbot"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings("marketingSource", e.target.value);
                }}
              >
                {marketingCodes.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem
                      key={i.sourcecd + "123123"}
                      value={i.sourcecd + ""}
                    >
                      {i.source}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={"rg-settings-row"}>
            <FormControl>
              <InputLabel id="call-status">Sale Call Status</InputLabel>
              <Select
                labelid="call-status"
                label="Sale Call Status"
                value={formData.callStatusCode || null}
                style={{ minWidth: 200 }}
                placeholder="Sales Call"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings("callStatusCode", e.target.value);
                }}
              >
                {callStatuses.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem key={i.status + "-dasd"} value={i.status + ""}>
                      {i.statusdesc}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel style={{ width: 180 }} id="call-action-reason">
                Sale Call Action Reason
              </InputLabel>
              <Select
                labelid="call-action-reason"
                label="Sale Call Action Reason"
                value={formData.callActionReason || null}
                style={{ minWidth: 200 }}
                placeholder="Sales Call"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings("callActionReason", e.target.value);
                }}
              >
                {callReasons.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem
                      key={i.actreasid + "-adas"}
                      value={i.actreasid + ""}
                    >
                      {i.actreas}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              id="crmPortalUrl"
              label="RealGreen Customer Portal"
              value={formData.crmPortalUrl || ""}
              style={{ margin: 8, width: "100%" }}
              placeholder="https://"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                updateRealGreenSettings("crmPortalUrl", e.target.value);
              }}
              onBlur={(e) => {
                updateRealGreenSettings("crmPortalUrl", e.target.value);
              }}
            />
          </div>
          <div className={"rg-settings-row"}>
            <FormControl>
              <InputLabel style={{ width: 180 }} id="newRecordStatus">
                New Record Status
              </InputLabel>
              <Select
                labelid="newRecordStatus"
                label="New Record Status"
                value={formData.crmNewRecordStatusScode}
                style={{ minWidth: 135 }}
                placeholder="0"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings(
                    "crmNewRecordStatusScode",
                    e.target.value
                  );
                }}
              >
                {statuses.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem key={key} value={i.value}>
                      {i.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel style={{ width: 180 }} id="crmQuoteStatusCode">
                Quote Received Status
              </InputLabel>
              <Select
                labelid="crmQuoteStatusCode"
                label="Quote Received Status"
                value={formData.crmQuoteStatusCode}
                style={{ minWidth: 135 }}
                placeholder="0"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings("crmQuoteStatusCode", e.target.value);
                }}
              >
                {statuses.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem key={key} value={i.value}>
                      {i.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="crmSaleStatusCode">Sale Made Status</InputLabel>
              <Select
                labelid="crmSaleStatusCode"
                label="Sale Made Status"
                value={formData.crmSaleStatusCode}
                style={{ minWidth: 135 }}
                placeholder="0"
                margin="normal"
                onChange={(e) => {
                  updateRealGreenSettings("crmSaleStatusCode", e.target.value);
                }}
              >
                {statuses.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <MenuItem key={key} value={i.value}>
                      {i.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <FormControl style={{ width: "100%" }}>
            <TextField
              id="crmRoute"
              label="RealGreen Route Label"
              value={
                typeof formData.crmRoute !== "undefined"
                  ? formData.crmRoute
                  : "null"
              }
              style={{ margin: 8, width: "100%" }}
              placeholder="XXX - Example Route"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                updateRealGreenSettings("crmRoute", e.target.value);
              }}
              onBlur={(e) => {
                updateRealGreenSettings("crmRoute", e.target.value);
              }}
            />
          </FormControl>
        </Fragment>
      )}
    </div>
  );
}
