/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_content_delivery_bucket:
    "lawnbotonboarding-20210824161452-hostingbucket-dev",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_url: "https://d2lsflb40vt3k3.cloudfront.net",
  aws_cognito_identity_pool_id:
    "us-east-1:f3521428-4b70-46f3-9050-b1c4e211f376",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_5zEEtmbwg",
  aws_user_pools_web_client_id: "5jv4km9a4b76h2v6pvao43niec",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"]
};

export default awsmobile;
