import React, { Fragment, useEffect, useState } from "react";
import { TableCell, Select, MenuItem, TextField } from "@material-ui/core";

function updatePriceRow(item, field, value, updateForm) {
  updateForm({ ...item, [field]: value });
}

export function PriceRow({ onChange, formData }) {
  const [item, setItem] = useState(formData);
  useEffect(() => {
    setItem(formData);
  }, [formData]);
  return (
    <Fragment>
      <TableCell>
        <TextField
          id="start"
          label="Start Size"
          style={{ marginTop: 8, width: 100 }}
          placeholder={"0"}
          value={item.start}
          margin="normal"
          onChange={(e) => {
            updatePriceRow(
              item,
              "start",
              e.target.value ? parseInt(e.target.value) : "",
              onChange
            );
          }}
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />
      </TableCell>
      <TableCell>
        <TextField
          id="end"
          label="End Size"
          style={{ marginTop: 8, width: 100 }}
          placeholder={"1000"}
          margin="normal"
          value={item.end}
          onChange={(e) =>
            updatePriceRow(
              item,
              "end",
              e.target.value ? parseInt(e.target.value) : "",
              onChange
            )
          }
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />
      </TableCell>
      <TableCell>
        <TextField
          id="price"
          label="Price"
          type="number"
          value={item.price}
          onChange={(e) =>
            updatePriceRow(
              item,
              "price",
              e.target.value ? parseFloat(e.target.value) : "",
              onChange
            )
          }
          style={{ marginTop: 8, width: 100 }}
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />
      </TableCell>
      <TableCell>
        <Select
          labelid="units"
          id="units"
          style={{ marginTop: 14, width: 100 }}
          value={item.units}
          onChange={(e) =>
            updatePriceRow(item, "units", e.target.value, onChange)
          }
          validators={["required"]}
        >
          {["1 sqft", "1000 sqft", "acres", "zones", "flat"].map((i) => {
            const key = "menu-item-" + Math.floor(Math.random() * 100000);
            return (
              <MenuItem key={key} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
    </Fragment>
  );
}
