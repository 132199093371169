import React from "react";
import * as _ from "lodash";
import {
  Select,
  TextField,
  TableCell,
  TableRow,
  Checkbox,
  MenuItem,
  Button
} from "@material-ui/core";
export function RGDiscountRows({
  index,
  round,
  rounds,
  handleDiscountChange,
  discountCodes
}) {
  return (
    <TableRow key={index}>
      <TableCell>
        <TextField
          id={`roudid_${Math.random() * 10000}`}
          style={{ width: 115, marginTop: 16 }}
          type="number"
          value={round.round}
          onChange={(e) => {
            const nrounds = [...rounds];
            nrounds[index].round = e.target.value;
            if (_.isFunction(handleDiscountChange)) {
              handleDiscountChange(nrounds);
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </TableCell>
      <TableCell style={{ paddingBottom: 0 }}>
        <Select
          id="codekey"
          style={{ width: 150 }}
          value={round.code ? round.code : ""}
          onChange={(e) => {
            const nrounds = [...rounds];
            nrounds[index].code = e.target.value;
            nrounds[index].discountData = discountCodes.find((i) => {
              return i.id === e.target.value && i.available;
            });
            if (_.isFunction(handleDiscountChange)) {
              handleDiscountChange(nrounds);
            }
          }}
          validators={["required"]}
        >
          {discountCodes
            .filter((i) => i.available)
            .filter((i) => i.discountDescription.trim())
            .map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);

              const discountAmount = i.discountAmount;
              const discountType =
                i.dollarDiscount === 1
                  ? discountAmount + "% Off"
                  : "$" +
                    discountAmount +
                    (i.dollarDiscount === 3 ? " Fixed Price" : " Off");
              return (
                <MenuItem key={key} value={i.id + ""}>
                  {i.discountDescription} ({discountType})
                </MenuItem>
              );
            })}
        </Select>
      </TableCell>
      <TableCell>
        <TextField
          id={`roudid_${Math.random() * 10000}`}
          type="number"
          value={round.sqftLowerRange}
          style={{ width: 120, marginTop: 16 }}
          onChange={(e) => {
            const nrounds = [...rounds];
            nrounds[index].sqftLowerRange = e.target.value;
            if (_.isFunction(handleDiscountChange)) {
              handleDiscountChange(nrounds);
            }
          }}
          InputLabelProps={{
            shrink: true,
            style: {
              width: 160
            }
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`roudid_${Math.random() * 10000}`}
          type="number"
          style={{ width: 120, marginTop: 16 }}
          value={round.sqftUpperRange}
          onChange={(e) => {
            const nrounds = [...rounds];
            nrounds[index].sqftUpperRange = e.target.value;
            if (_.isFunction(handleDiscountChange)) {
              handleDiscountChange(nrounds);
            }
          }}
          InputLabelProps={{
            shrink: true,
            style: {
              width: 160
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={round.isSlidingScale}
          onChange={(e) => {
            const nrounds = [...rounds];
            nrounds[index].isSlidingScale = e.target.checked;
            if (_.isFunction(handleDiscountChange)) {
              handleDiscountChange(nrounds);
            }
          }}
          name="slidingScale"
        />
      </TableCell>
      <TableCell>
        <Button
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            const nrounds = [...rounds];
            nrounds.splice(index, 1);
            if (_.isFunction(handleDiscountChange)) {
              handleDiscountChange(nrounds);
            }
          }}
        >
          X
        </Button>
      </TableCell>
    </TableRow>
  );
}
