import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Input,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import ImageCropper from "./ImageCropper.jsx";
import "react-image-crop/dist/ReactCrop.css";
import "react-toastify/dist/ReactToastify.css";
import "./brandingInfo.css";
import { ToastContainer } from "react-toastify";
import { createImage } from "./branding-helpers.js";
export function BrandingImage(props) {
  const {
    field,
    image,
    uploaded,
    aspect,
    type,
    allowableContentType,
    fieldName,
    useHeader = true
  } = props;
  const toastId = React.useRef(null);
  const [targetImage, setTargetImage] = useState(null);
  const [tisVideo, setTisVideo] = useState(false);
  const [croppingImage, toggleCroppingImage] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  useEffect(() => {
    let timage = image || null;
    let isVideo = false;
    if (timage) {
      timage = timage.split("?")[0];
      if (timage.indexOf(".mp4") !== -1) {
        isVideo = true;
      }
    }
    setTargetImage(timage);
    setTisVideo(isVideo);
  }, [image]);

  const uploadFile = async (originalFile, croppedImage) => {
    const name = originalFile.name;
    const lastDot = name.lastIndexOf(".");
    const ext = name.substring(lastDot + 1);
    let contentType =
      originalFile.name.indexOf(".mp4") !== -1 ? "video/mp4" : `image/${ext}`;
    const savedImage = await createImage(
      field,
      croppedImage || originalFile,
      contentType,
      ext,
      toastId,
      fieldName
    );
    if (savedImage) {
      setTargetImage(croppedImage);
      setOriginalFile(null);
      props.handleChange({
        ...props.formData,
        [props.field]: savedImage
      });
    } else {
      setTargetImage(null);
      const input = document.getElementById(`${field}-input`);
      if (input) {
        input.value = "";
      }
    }
  };

  const onChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.name.indexOf(".mp4") !== -1) {
        uploadFile(file);
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImageToCrop(reader.result);
          toggleCroppingImage(true);
        });
        reader.readAsDataURL(file);
        setOriginalFile(file);
      }
    }
  };

  const removeImage = (contentType) => {
    setTargetImage(null);
    uploaded(type, null, contentType, true);
  };

  const displayElement = () => {
    if (!tisVideo) {
      return (
        <DisplayImage
          {...props}
          contentType="image/png"
          removeImage={removeImage}
          allowableContentType={allowableContentType}
          onChange={onChange}
          id={field}
          field={targetImage}
          label={"Image"}
        />
      );
    } else {
      return (
        <DisplayVideo
          {...props}
          contentType="video/mp4"
          removeImage={removeImage}
          field={field}
          label={"Video"}
        />
      );
    }
  };
  return (
    <Fragment>
      <div>
        {useHeader && <h1>{props.fieldName}</h1>}
        {targetImage ? (
          displayElement()
        ) : (
          <div>
            {props.inputLabel}

            <Input
              id={`${field}-input`}
              className="image-input"
              type="file"
              accept={allowableContentType}
              onChange={(e) => onChange(e)}
              disableUnderline={true}
              style={props.inputStyle}
            />
          </div>
        )}
        <ToastContainer />
        <Dialog open={croppingImage} onClose={() => toggleCroppingImage(false)}>
          <DialogContent>
            <ImageCropper
              aspect={aspect}
              field={field}
              circular={props.circular}
              insertedImage={originalFile}
              orgnlImage={imageToCrop}
              setCroppedImage={setCroppedImage}
            />
            <DialogActions>
              <Button
                onClick={() => {
                  setImageToCrop(null);
                  setCroppedImage(null);
                  toggleCroppingImage(false);
                  const input = document.getElementById(`${field}-input`);
                  if (input) {
                    input.value = "";
                  }
                }}
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  uploadFile(originalFile, croppedImage);
                  setImageToCrop(null);
                  setCroppedImage(null);
                  toggleCroppingImage(false);
                }}
              >
                Crop
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </Fragment>
  );
}

function DisplayVideo(props) {
  const { field, removeImage, label, contentType } = props;

  return (
    <div>
      <video muted width="500" controls>
        <source src={field} type="video/mp4"></source>
      </video>
      <Button
        style={{ backgroundColor: "red" }}
        onClick={() => removeImage(contentType)}
        color="red"
        variant="contained"
      >
        Remove {label}
      </Button>
    </div>
  );
}

function DisplayImage(props) {
  const { field, onChange, allowableContentType } = props;
  const hiddenFileInput = React.useRef(null);
  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };
  return (
    <div>
      <input
        ref={hiddenFileInput}
        type="file"
        style={{
          display: "none"
        }}
        accept={allowableContentType}
        onChange={(e) => onChange(e)}
      />
      <button
        onClick={() => handleClick()}
        className={`${
          props.imageButtonClassName
            ? props.imageButtonClassName
            : "branding-image-button"
        }`}
      >
        <img
          style={{
            padding: "5px",
            ...(props.imageStyle ? props.imageStyle : {}),
            "&:hover": {
              background: "#efefef"
            },
            ...(props.circular ? { borderRadius: "50%" } : {})
          }}
          className={`branding-image`}
          src={field}
          alt="target"
        />
        <div>
          <i className="fas fa-camera"></i>
        </div>
      </button>
    </div>
  );
}
