export function getBrandingSteps() {
  return {
    "branding-start": {
      formDataKey: "companyBranding",
      nextStepOptions: ["branding-images"]
    },
    "branding-images": {
      formDataKey: "companyBranding",
      nextStepOptions: ["questions-page"]
    }
  };
}
