import React, { useEffect, useState } from "react";
import { GetOnboardingData } from "../../context/onboarding-data";
import { MenuItem, TextField } from "@material-ui/core";
const type = {
  realGreenPrepayDiscountCode: {
    key: "rgCodes",
    display: ["discountDescription", "discountType"],
    title: "Pre-Pay Discount Code"
  },
  crmProgramCode: {
    key: "crmProgramCodes",
    display: ["prgm_desc", "prgm_code"],
    title: "Real Green Program"
  }
};

function updateProgram(value, updateForm) {
  updateForm(value);
}

export function PrePayDropdowns({ formData, onChange, label }) {
  const onBoardingData = GetOnboardingData();
  const tableContents =
    type[label] && onBoardingData[type[label].key]
      ? onBoardingData[type[label].key]
      : [];
  const [item, setItem] = useState(formData);
  useEffect(() => {
    setItem(formData);
  }, [formData]);
  return onBoardingData.isRealGreen ? (
    <div>
      <TextField
        labelid="priceTableId"
        select
        label={type[label] ? type[label].title : "Placeholder Title"}
        id="priceTableId"
        style={{ marginTop: 16, width: "100%" }}
        value={item}
        onChange={(e) => {
          updateProgram(e.target.value, onChange);
        }}
        validators={["required"]}
      >
        {tableContents &&
          tableContents.map((i) => {
            const displayType = i[type[label].display[1]]
              ? i[type[label].display[1]]
              : i.dollarDiscount === 1
              ? i.discountAmount + "% Off"
              : "$" +
                i.discountAmount +
                (i.dollarDiscount === 3 ? " Fixed Price" : " Off");
            const key = "menu-item-" + Math.floor(Math.random() * 100000);
            return (
              <MenuItem key={key} value={i.id ? i.id : i.progdefid + ""}>
                {i[type[label].display[0]]} ({displayType})
              </MenuItem>
            );
          })}
      </TextField>
    </div>
  ) : null;
}
