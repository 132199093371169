export function getQuestionSteps() {
  return {
    "questions-page": {
      formDataKey: "botQuestions",
      isArray: true,
      nextStepOptions: ["objections"]
    },
    "edit-question": {
      formDataKey: "botQuestions",
      editing: "editingQuestionIndex",
      propertyToEdit: "botQuestions",
      isArray: true,
      nextStepOptions: ["questions-page"],
      recordNextStep: false
    },
    objections: {
      formDataKey: "objections",
      nextStepOptions: ["serviceQuestions"]
    },
    serviceQuestions: {
      formDataKey: "serviceQuestions",
      isArray: true,
      nextStepOptions: ["final-step"]
    },
    "edit-service-question": {
      formDataKey: "serviceQuestions",
      editing: "editingServiceQuestionIndex",
      propertyToEdit: "serviceQuestions",
      isArray: true,
      nextStepOptions: ["serviceQuestions"],
      recordNextStep: false
    }
  };
}
