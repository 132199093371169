import { QuestionsPage } from "../../step-component/custom-steps/questions/QuestionsPage.jsx";
import { EditQuestionPage } from "../../step-component/custom-steps/questions/EditQuestionPage.jsx";
import { Objections } from "../../step-component/custom-steps/questions/objections/Objections.jsx";

export function getQuestionsUI() {
  return {
    "questions-page": () => {
      return {
        "ui:ArrayFieldTemplate": QuestionsPage
      };
    },
    "edit-question": () => {
      return {
        "ui:FieldTemplate": EditQuestionPage
      };
    },
    objections: () => {
      return {
        "ui:FieldTemplate": Objections
      };
    },
    serviceQuestions: () => {
      return {
        "ui:ArrayFieldTemplate": QuestionsPage
      };
    },
    "edit-service-question": () => {
      return {
        "ui:FieldTemplate": EditQuestionPage
      };
    }
  };
}
