import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Container,
  CircularProgress
} from "@material-ui/core";
import CodeBlock from "@tenon-io/tenon-codeblock";
import { generateCode, validateProgramsAndCompany } from "./util";
import { API } from "aws-amplify";
import { GetOnboardingData } from "../../../context/onboarding-data";
import "./final.css";
import { ValidationErrors } from "./ValidationErrors.jsx";

export function FinalStep({ formData }) {
  const {
    botId,
    programs,
    company,
    botQuestions,
    serviceQuestions,
    botSettings
  } = GetOnboardingData();

  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState("");
  const [finished, setFinished] = useState(false);
  useEffect(() => {
    setConfirm("");
  }, []);
  useEffect(() => {
    if (programs && Object.values(programs).length && company) {
      setValidationErrors(
        validateProgramsAndCompany(
          programs,
          company,
          botQuestions,
          serviceQuestions,
          botSettings
        )
      );
    } else if (!programs || !Object.values(programs).length) {
      setValidationErrors({
        program: {
          string:
            "You have no programs setup. Please return to the programs page and set up some programs.",
          location: "program-page"
        }
      });
    }
  }, [programs, company, botQuestions, serviceQuestions, botSettings]);
  useEffect(() => {
    if (formData.approved) {
      setFinished(true);
    }
  }, [formData, setFinished]);
  function handleClose() {
    setConfirm("");
    setOpen(false);
  }

  return (
    <div>
      {finished ? (
        <Container style={{ height: "calc(100vh - 101px)" }}>
          <Typography style={{ marginBottom: 20 }} variant={"h5"}>
            Your Bot Has Been Published!
            <hr className="MuiDivider-root" />
          </Typography>

          <Typography variant={"p"}>
            Your onboarding to Lawnbot is complete. If applicable, use the
            iframe below to add the bot to your website/webpress page.
          </Typography>
          <div id="iframe-container">
            {" "}
            <CodeBlock
              codeString={generateCode(
                formData.id,
                botId,
                formData.companyName
              )}
              language="html"
              style={{ textAlign: "left" }}
            />
          </div>
        </Container>
      ) : error ? (
        <Container style={{ height: "calc(100vh - 101px)" }}>
          <p>!!!ERROR!!! {error}</p>
        </Container>
      ) : loading ? (
        <Container style={{ height: "calc(100vh - 101px)" }}>
          {" "}
          <div
            style={{
              height: "100px",
              textAlign: "center",
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto"
            }}
          >
            <CircularProgress style={{ margin: "auto" }} />
            <span>Sending Bot Data...</span>
          </div>
        </Container>
      ) : (
        <div>
          <Typography style={{ marginBottom: 20 }} variant={"h5"}>
            Preview and Finalize
          </Typography>
          <hr className="MuiDivider-root" />
          <Button
            target="_blank"
            variant="contained"
            style={{ width: "100%", marginTop: 30 }}
            color="primary"
            disabled={Object.keys(validationErrors).length ? true : false}
            href={`http://sales.lawnbot.biz/${formData.id}?onboarding=true`}
          >
            Preview
          </Button>
          <Button
            style={{ width: "100%", marginTop: 30 }}
            color="secondary"
            variant="contained"
            disabled={Object.keys(validationErrors).length ? true : false}
            onClick={() => setOpen(true)}
          >
            Finalize
          </Button>
          {Object.keys(validationErrors).length ? (
            <ValidationErrors validationErrors={validationErrors} />
          ) : null}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Finalize Your Bot</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`!!!WARNING!!! You are about to finalize your bot. Please be sure of
            your current settings before publishing. Any changes you need to
            make after publishing will need to be made though the self-serve
            features present in the dashboard client or by contacting support.
            Type 'confirm' in the field below to continue.`}
              </DialogContentText>
              <TextField
                value={confirm}
                onChange={(e) => setConfirm(e.target.value)}
                margin="dense"
                id="confirm"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button
                disabled={confirm.toLowerCase() !== "confirm"}
                onClick={async () => {
                  setLoading(true);
                  handleClose();
                  setError("");
                  const result = await API.post(
                    "dashboardapi",
                    `/v1/company/${formData.id}/onboarding`,
                    {}
                  ).catch((error) => setError(error.response.data.message));
                  if (result && !result.error) {
                    setFinished(true);
                  }

                  setLoading(false);
                }}
              >
                Finalize
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}
