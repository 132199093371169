import React, { Fragment, useEffect, useState } from "react";
import {
  TableCell,
  Select,
  MenuItem,
  TextField,
  Button,
  TableRow
} from "@material-ui/core";

function updateProgram(item, field, value, updateForm) {
  updateForm({ ...item, [field]: value });
}

export function EditOfferProgramRow({
  program,
  removeProgram,
  updateProgramsArray,
  availablePrograms = [],
  priceTables = {}
}) {
  const [item, setItem] = useState(program);
  useEffect(() => {
    setItem(program);
  }, [program]);
  return (
    <TableRow>
      <TableCell>
        <Select
          id="programId"
          style={{ marginTop: 14, width: 150 }}
          value={item.programId}
          onChange={(e) =>
            updateProgram(
              item,
              "programId",
              e.target.value,
              updateProgramsArray
            )
          }
          validators={["required"]}
        >
          {availablePrograms.map((i) => {
            const key = "menu-item-" + Math.floor(Math.random() * 100000);
            return (
              <MenuItem key={key} value={i.id}>
                {i.name}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell>
        <Select
          id="units"
          style={{ marginTop: 14, width: 150 }}
          value={item.discountType}
          onChange={(e) => {
            updateProgram(
              item,
              "discountType",
              e.target.value,
              updateProgramsArray
            );
          }}
          validators={["required"]}
        >
          {[
            { id: "fixed-price", name: "Fixed Price" },
            { id: "percentage-off", name: "Percentage Off" },
            { id: "discounted-price-table", name: "Discounted Price Table" }
          ].map((i) => {
            return (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell>
        <Select
          id="appliedTo"
          style={{ marginTop: 14, width: 150 }}
          value={item.appliedTo}
          onChange={(e) => {
            updateProgram(
              item,
              "appliedTo",
              e.target.value,
              updateProgramsArray
            );
          }}
          validators={["required"]}
        >
          {[
            { id: "first-app", name: "First Application" },
            { id: "entire-program", name: "Entire Program" }
          ].map((i) => {
            return (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell>
        {program.discountType === "discounted-price-table" ? (
          <Select
            labelid="priceTables"
            id="priceTables"
            placeholder="Select Price Table"
            label="Select Price Table"
            style={{
              marginTop: 14
            }}
            value={program.discountPriceTableId}
            onChange={(e) => {
              updateProgram(
                item,
                "discountPriceTableId",
                e.target.value,
                updateProgramsArray
              );
            }}
            validators={["required"]}
          >
            {Object.values(priceTables).map((i) => {
              return <MenuItem key={i.id} value={i.id}>{`${i.name}`}</MenuItem>;
            })}
          </Select>
        ) : (
          <TextField
            id="amount"
            style={{ marginTop: 22, width: 100 }}
            placeholder={"0"}
            value={item.discountAmount}
            margin="normal"
            onChange={(e) => {
              updateProgram(
                item,
                "discountAmount",
                e.target.value ? parseFloat(e.target.value) : "",
                updateProgramsArray
              );
            }}
            InputLabelProps={{
              shrink: true
            }}
            validators={["required"]}
          />
        )}
      </TableCell>
      <TableCell>
        <Button onClick={removeProgram} color="secondary">
          X
        </Button>
      </TableCell>
    </TableRow>
  );
}
