import {
  faAlignCenter,
  faEdit,
  faPencilAlt,
  faThList
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

export const questionSchema = {
  "questions-page": {
    required: ["title"],

    type: "array",
    default: [],
    items: {
      type: "object",
      properties: {
        type: { type: "string", default: "default" },
        question: { type: "string", default: "New Question" },
        options: {
          type: "array",
          default: null,
          items: {
            type: "object",
            text: { type: "string" }
          }
        }
      }
    },
    icon: faAlignCenter,
    title: "Bot Questions",
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Ask Additional Questions To Make Quoting Better!
        </Typography>
        <hr />

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Do you want to ask questions during the Lawnbot experience? Asking
          questions can provide additional insight to what services your
          potential customers will want and need.
        </Typography>

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          The answers to these questions will be included in your
          email/dashboard to review later. You can choose the order of the
          questions by moving them up/down in the list.
        </Typography>
      </div>
    )
  },
  "edit-question": {
    type: "object",
    title: "Edit Question",
    navigationButtons: ["Back to Bot Questions", null],
    properties: {
      question: { type: "string", default: "New Question" },
      type: { type: "string", default: "default" },
      options: {
        type: "array",
        default: [],
        items: {
          type: "object",
          text: { type: "string" }
        }
      }
    },
    icon: faEdit,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Go ahead and ask your question!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Types of Questions You Can Ask:
        </Typography>
        <ul className="step-description-ul">
          <li>
            <img
              alt="free-form"
              src="/images/free-form.png"
              style={{
                maxWidth: 160,
                margin: 10,
                position: "relative",
                float: "right"
              }}
            />
            <u>Free Form</u> - The customer can type their response
          </li>
          <li>
            {" "}
            <img
              alt="free-form"
              src="/images/text-option.png"
              style={{
                maxWidth: 85,
                margin: 10,
                position: "relative",
                float: "right"
              }}
            />
            <u>Text Options</u> - Create a list of buttons with text for the
            customer to choose from{" "}
          </li>
          <li>
            <img
              alt="free-form"
              src="/images/image-option.png"
              style={{
                maxWidth: 60,
                margin: 10,
                position: "relative",
                float: "right"
              }}
            />
            <u>Image Options</u> - Create a list of images buttons for the
            customer to choose from
          </li>
          <li>
            <img
              alt="free-form"
              src="/images/icon-option.png"
              style={{
                maxWidth: 100,
                margin: 10,
                position: "relative",
                float: "right"
              }}
            />
            <u>Icon Options</u> - Create a list of buttons with icons for the
            customer to choose from
          </li>
        </ul>
      </div>
    )
  },
  objections: {
    required: ["title"],

    type: "object",
    properties: {
      price: {
        type: "object",
        properties: {
          type: { type: "string", default: "company-objection-price" },

          question: {
            type: "string",
            default:
              "I understand! We may not be the cheapest but with us you get a family-owned and operated company, that will give you the personal attention a beautiful lawn requires...."
          }
        }
      },
      people: {
        type: "object",
        properties: {
          type: { type: "string", default: "company-objection-people" },

          question: {
            type: "string",
            default:
              "We a local family-owned business right here in your area. With us you get the hands-on personal touch that gets lost when dealing with large national companies!"
          }
        }
      },
      product: {
        type: "object",
        properties: {
          type: { type: "string", default: "company-objection-product" },

          question: {
            type: "string",
            default:
              "We use the newest technology to decrease the use of pesticides while improving the overall look and health of your lawn. We strongly believe the biggest obstacle in our industry is having uneducated customers. That’s why our goal is to build a company that revolutionizes the green industry by bridging the gap between customers and company."
          }
        }
      }
    },
    icon: faAlignCenter,
    title: "Objections",
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Find out why a potential customer wants to think more before
          purchasing your services!
        </Typography>
        <hr />

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          A potential customer may not be sure if they want to go through with
          purchasing services. The typical reasons are pricing, products used,
          or the people providing the service. This step allows you to make your
          case for each.
        </Typography>

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          The answers to these questions will be included in your
          email/dashboard to review later.
        </Typography>
      </div>
    )
  },
  serviceQuestions: {
    required: ["title"],
    type: "array",
    default: [],
    items: {
      type: "object",
      properties: {
        type: { type: "string", default: "default" },
        question: { type: "string", default: "New Question" },
        options: {
          type: "array",
          default: null,
          items: {
            type: "object",
            text: { type: "string" }
          }
        }
      }
    },
    icon: faAlignCenter,
    title: "Service Questions",
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Ask Additional Questions After Services Have Been Purchased!
        </Typography>
        <hr />

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Do you need more info from your new customer such as if they have a
          locked gate or preferred method of contact? Use this step to setup
          questions to ask the customer after they&apos;ve purchased your
          services.
        </Typography>

        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          The answers to these questions will be included in your
          email/dashboard to review later. You can choose the order of the
          questions by moving them up/down in the list.
        </Typography>
      </div>
    )
  },
  "edit-service-question": {
    type: "object",
    title: "Edit Question",
    navigationButtons: ["Back to Service Questions", null],
    properties: {
      question: { type: "string", default: "New Question" },
      type: { type: "string", default: "default" },
      options: {
        type: "array",
        default: [],
        items: {
          type: "object",
          text: { type: "string" }
        }
      }
    },
    icon: faEdit,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Go ahead and ask your question!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Types of Questions You Can Ask:
        </Typography>
        <ul className="step-description-ul">
          <li>
            <img
              alt="free-form"
              src="/images/free-form.png"
              style={{
                maxWidth: 160,
                margin: 10,
                position: "relative",
                float: "right"
              }}
            />
            <u>Free Form</u> - The customer can type their response
          </li>
          <li>
            {" "}
            <img
              alt="free-form"
              src="/images/text-option.png"
              style={{
                maxWidth: 85,
                margin: 10,
                position: "relative",
                float: "right"
              }}
            />
            <u>Text Options</u> - Create a list of buttons with text for the
            customer to choose from{" "}
          </li>
          <li>
            <img
              alt="free-form"
              src="/images/image-option.png"
              style={{
                maxWidth: 60,
                margin: 10,
                position: "relative",
                float: "right"
              }}
            />
            <u>Image Options</u> - Create a list of images buttons for the
            customer to choose from
          </li>
          <li>
            <img
              alt="free-form"
              src="/images/icon-option.png"
              style={{
                maxWidth: 100,
                margin: 10,
                position: "relative",
                float: "right"
              }}
            />
            <u>Icon Options</u> - Create a list of buttons with icons for the
            customer to choose from
          </li>
        </ul>
      </div>
    )
  }
};
