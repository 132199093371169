import React, { useEffect, useState } from "react";
import { RGDiscountRows } from "./RGDiscountRows.jsx";
import {
  TableCell,
  TableHead,
  Table,
  TableBody,
  Container,
  Button
} from "@material-ui/core";

import { GetOnboardingData } from "../../../../context/onboarding-data.js";
function setDiscountCode(setRounds, handleChange) {
  return (e) => {
    setRounds(e);
    handleChange(JSON.stringify(e));
  };
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function RealGreenApplicationDiscounts({ onChange, formData }) {
  const { rgCodes } = GetOnboardingData();

  const [rounds, setRounds] = useState([]);

  useEffect(() => {
    if (formData) {
      const realGreenApplicationDiscountCode = formData || [];
      setRounds(
        isJson(realGreenApplicationDiscountCode)
          ? JSON.parse(realGreenApplicationDiscountCode)
          : realGreenApplicationDiscountCode
      );
    }
  }, [formData]);

  const discountRows = rounds.map((round, i) => {
    return (
      <RGDiscountRows
        key={i}
        index={i}
        round={round}
        rounds={rounds}
        handleDiscountChange={setDiscountCode(setRounds, onChange)}
        discountCodes={rgCodes}
      />
    );
  });
  return (
    <div>
      <div style={{ overflow: "scroll", maxHeight: 370 }}>
        <Table>
          <TableHead>
            <TableCell>Round</TableCell>
            <TableCell>Discount Code</TableCell>
            <TableCell>Lower Square Footage Range</TableCell>
            <TableCell>Upper Square Footage Range</TableCell>
            <TableCell>Sliding Scale</TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>{discountRows}</TableBody>
        </Table>
      </div>
      <Button
        style={{ float: "right", position: "relative", marginTop: 20 }}
        color="primary"
        variant="contained"
        onClick={() => {
          setRounds((prevData) => {
            return [
              ...prevData,
              {
                round: 0,
                code: null,
                isSlidingScale: false,
                sqftLowerRange: 0,
                sqftUpperRange: 1000,
                discountData: {}
              }
            ];
          });
        }}
      >
        Add
      </Button>
    </div>
  );
}
