import React, { Fragment } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";

export function ServicesArray({ items, onAddClick }) {
  return (
    <Fragment>
      <div
        style={{
          marginBottom: 10,
          textAlign: "right"
        }}
      >
        <Button color={"text"} onClick={onAddClick}>
          <i
            style={{ color: "green", marginRight: 5 }}
            className="fas fa-plus-circle"
          ></i>
          Create Service
        </Button>
      </div>
      <div style={{ overflow: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Service Name</TableCell>
              <TableCell>Round Number</TableCell>
              <TableCell>Price Table</TableCell>
              <TableCell>Cut Off Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((element, index) => {
              return (
                <TableRow key={index}>
                  {element.children}
                  <TableCell>
                    <Button
                      onClick={element.onDropIndexClick(index)}
                      color="secondary"
                    >
                      X
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Fragment>
  );
}
