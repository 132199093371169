import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";

export function WelcomeScreen({ formData, onChange }) {

return (<Fragment>
    <img alt="welcome" 
    style={{width: '80%'}}
    src="https://secureservercdn.net/50.62.89.111/dvf.3e3.myftpupload.com/wp-content/uploads/2021/07/Lawnbot_2021-by-workwave.png" />
    
    <h2>Welcome To Lawnbot</h2>
    <br />
    <Typography paragraph={true} style={{textAlign: 'left', marginLeft: '10%', width: '80%'}}>
    Lawnbot generates highly accurate estimates while capturing all-important contact information from every prospect. Our chatbot can instantly answer many common questions and even handle common objections through a streamlined sales process. Lawnbot makes selling your services online painless and simple.
    </Typography>
</Fragment>)

}