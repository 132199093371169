import { brandingSchema } from "./branding-schema";
import { priceTableSchema } from "./price-table-schema";
import { servicesSchema } from "./services-schema";
import { offerSchema } from "./offer-schema";
import { questionSchema } from "./question-schema";
import { companySchema } from "./company-schema";
import {
  faMoneyBillWaveAlt,
  faPencilAlt,
  faTag,
  faTags,
  faThList
} from "@fortawesome/free-solid-svg-icons";
import {
  monthDefinition,
  paymentSchema,
  autoPayDefinition
} from "./payment-schema";
import { programSchema, serviceTypesDefinition } from "./program-schema";
import { welcomeSchema } from "./welcome-schema";
import { botSchema } from "./bot-schema";
import { Typography } from "@material-ui/core";

export const stepSchemaIndex = {
  definitions: {
    monthDefinition,
    autoPayDefinition,
    serviceTypesDefinition
  },
  ...welcomeSchema,
  ...companySchema,
  ...programSchema,
  ...brandingSchema,
  ...paymentSchema,
  ...priceTableSchema,
  ...servicesSchema,
  ...offerSchema,
  ...questionSchema,
  ...botSchema,
  "final-step": {
    type: "object",
    title: "Preview and Finalize",
    icon: faTags,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          {"You're almost there!"}
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          On this screen you may preview and finalize your bot. Click on the
          preview button to test your bot in realtime. Be sure to come back here
          afterwards to make any changes. If everything is in working order, you
          may go ahead and finalize your bot.
          <br />
        </Typography>
      </div>
    )
  }
};
