import { BrandingImagePage } from "../../step-component/custom-steps/branding/BrandingImagePage.jsx";
import { BrandingStartPage } from "../../step-component/custom-steps/branding/BrandingStartPage.jsx";
export function getBrandingUI() {
  return {
    "branding-start": () => {
      return { "ui:FieldTemplate": BrandingStartPage };
    },
    "branding-images": () => {
      return {
        "ui:FieldTemplate": BrandingImagePage
      };
    }
  };
}
