import { TestPricing } from "../../step-component/custom-steps/price-testing/TestPricing.jsx";
export function getTestPricingUI() {
  return {
    "test-pricing": () => {
      return {
        "ui:FieldTemplate": TestPricing
      };
    }
  };
}
