import React from "react";
import { toCapitalizedWords } from "./util";
import { Button } from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data";
export function ValidationErrors({ validationErrors }) {
  const { goToNextStepFunc } = GetOnboardingData();
  return (
    <div
      id="validation-errors"
      style={{
        whiteSpace: "pre-wrap",
        color: "red",
        marginTop: 30
      }}
    >
      {`!!!WARNING!!! We have detected some errors in your setup. These errors will cause your bot to not work properly. Please fix these errors before moving on.`}
      <hr />
      {Object.keys(validationErrors).map((key, index) => {
        return (
          <div key={index}>
            {" "}
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <h3 style={{ margin: 0 }}>{toCapitalizedWords(key)} Errors</h3>{" "}
              {" -"}
              <Button
                style={{ paddingTop: 8, marginLeft: 8 }}
                variant={"contained"}
                onClick={() => {
                  goToNextStepFunc(validationErrors[key].location);
                }}
              >{`To ${key.replace(
                /([a-z0-9])([A-Z])/g,
                "$1 $2"
              )} page`}</Button>
            </div>
            <p>{`${validationErrors[key].string}`}</p>
            {index !== Object.keys(validationErrors).length - 1 ? <hr /> : null}
          </div>
        );
      })}
    </div>
  );
}
