import { CompanyGeneralPricing } from "../../step-component/custom-steps/company/CompanyGeneralPricing.jsx";
import { GeneralCustomerInfo } from "../../step-component/custom-steps/company/GeneralCustomerInfo.jsx";
import { RealGreenPage } from "../../step-component/custom-steps/company/RealGreenPage.jsx";
import { CreateTestClient } from "../../step-component/custom-steps/company/CreateTestClient.jsx";
export function getCompanyUI() {
  return {
    "company-page": () => {
      return {
        "ui:FieldTemplate": GeneralCustomerInfo
      };
    },
    "company-pricing": () => {
      return {
        "ui:FieldTemplate": CompanyGeneralPricing
      };
    },
    "real-green-settings": () => {
      return {
        "ui:FieldTemplate": RealGreenPage
      };
    },
    "create-test-customer": () => {
      return {
        "ui:FieldTemplate": CreateTestClient
      };
    }
  };
}
