import React, { Fragment } from "react";
import { SelectValidator } from "react-material-ui-form-validator";
import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import { currencies } from "../currencies";
export function CurrencyField({ formData, onChange }) {
  return (
    <FormControl>
      <InputLabel id="customerCurrency">Currency</InputLabel>
      <Select
        label="Currency"
        labelid="customerCurrency"
        id="currency-select"
        value={formData}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        style={{ minWidth: 90 }}
        errorMessages={["this field is required"]}
        validators={["required"]}
      >
        {currencies.map(({ name }, index) => {
          const key = "currency-dropdown-" + Math.floor(Math.random() * 100000);
          return (
            <MenuItem key={key} value={name}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
