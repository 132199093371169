import React from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import { addNewProgram } from "./programs-table-helpers.js";
import { ProgramRow } from "./ProgramRow.jsx";
import { GetOnboardingData } from "../../../context/onboarding-data.js";
export function ProgramsTable({ formData, onChange }) {
  const { isRealGreen, goToNextStepFunc, setEditingProgramId } =
    GetOnboardingData();
  const programRows = Object.keys(formData ? formData : {}).map((id) => {
    return (
      <ProgramRow
        key={`program-row-${id}`}
        program={formData[id]}
        handleChange={onChange}
        formData={formData}
        setEditingProgramId={setEditingProgramId}
        handleGoToNextStep={goToNextStepFunc}
      />
    );
  });

  return (
    <div>
      <Box style={{ marginBottom: 10 }}>
        <Typography variant={"h5"}>Programs</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <Container>
        <Button
          style={{
            float: "right",
            position: "relative",
            marginBottom: 15
          }}
          variant="text"
          onClick={addNewProgram(
            onChange,
            formData,
            setEditingProgramId,
            goToNextStepFunc,
            isRealGreen
          )}
        >
          <i
            style={{ color: "green", marginRight: 5 }}
            className="fas fa-plus-circle"
          ></i>
          Create Program
        </Button>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Program Name</TableCell>
              <TableCell>Program Description</TableCell>
              <TableCell>Program Type</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ position: "relative" }}>{programRows}</TableBody>
        </Table>
      </Container>
    </div>
  );
}
