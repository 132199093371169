import {
  ProgramsTable,
  ServiceTypeSelector,
  RealGreenApplicationDiscounts
} from "../../step-component/custom-steps/programs";
import { PrePayDropdowns } from "../../step-component/custom-steps/PrePayDropdowns.jsx";

export function getProgramUI() {
  return {
    "edit-program": (props) => {
      return {
        crmProgramCode: {
          "ui:FieldTemplate": PrePayDropdowns
        },
        programType: {
          "ui:FieldTemplate": ServiceTypeSelector
        },
        "ui:title": "Edit Program"
      };
    },
    "edit-program-application-discounts": () => {
      return {
        realGreenApplicationDiscountCode: {
          "ui:FieldTemplate": RealGreenApplicationDiscounts
        }
      };
    },
    "program-page": () => {
      return {
        "ui:FieldTemplate": ProgramsTable
      };
    }
  };
}
