import {
  faArrowCircleDown,
  faFileCsv,
  faPencilAlt,
  faTable,
  faThList
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

export const priceTableSchema = {
  "edit-upload-price-table": {
    type: "object",
    title: "Upload Price Tables",
    icon: faTable,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Price tables let Lawnbot know what to charge.
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          You can upload more than one pricing table depending on your needs.
        </Typography>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Later we will tie these price tables each individual
          round/service/application in this program.
        </Typography>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          {`Click the 'create price table' button to begin setting up a new price table.`}
        </Typography>
      </div>
    )
  },
  "edit-price-table": {
    type: "object",
    title: "Define Price Table",
    properties: {
      name: { type: "string", title: "Price Table Name" },
      year: { type: "string", title: "Year" },
      prePayDiscount: { type: "number", title: "Pre-Pay Discount" },
      priceRows: {
        type: "array",
        title: "Price Rows",
        items: {
          type: "object",
          properties: {
            start: { type: "number", default: 0 },
            end: { type: "number", default: 0 },
            units: {
              type: "string",
              default: "1000 sqft"
            },
            price: { type: "number", default: 0 }
          }
        }
      }
    },
    icon: faArrowCircleDown,
    navigationButtons: ["Back to Price Table Page", null],
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Let&apos;s Define Your Price Table
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          A Price Table in Lawnbot is a{" "}
          <u>
            <b>list of size ranges with associated prices</b>
          </u>{" "}
          for this program.
        </Typography>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        ></Typography>
        <ul className="step-description-ul">
          <li>
            <u>Price Table Name</u> - Enter a descriptive name (e.g.
            &apos;Residental Prices&apos;, &apos;Fall Special Prices&apos;)
          </li>
          <li>
            <u>Year</u> - Which year this price table is applicable ( price
            table years will roll to the next year if not changed)
          </li>
          <li>
            <u>Pre-Pay Discount</u> - If you offer Pre-Pay option for your
            program then enter it here (e.g. 10 = 10%)
          </li>
          <li>
            <u>Price Rows</u> - The start and end fields determine what range
            the price will apply to. The unit field determines what the start
            and end fields are measuring. Example: If you fill in
            {`"0", "5", "45", "1000 sqft"`}, this means that any service applied
            to yards measuring 0 - 5000 sqft will have a price of $45 (or your
            applicable currency). You can auto fill the price rows by
            downloading the provided template and reuploading it in csv format
            or you can add rows one by one by clicking &apos;ADD PRICE ROW&apos;
          </li>
        </ul>
      </div>
    )
  }
};
