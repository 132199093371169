import React, { useEffect, useState } from "react";
import {
  TableCell,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import "./edit-offer.css";
import { EditOfferProgramArray } from ".";

function updateOffer(item, field, value, updateForm) {
  updateForm({ ...item, [field]: value });
}

function updatePrograms(formData, onChange) {
  return (newProgramArray) => {
    updateOffer(formData, "programs", newProgramArray, onChange);
  };
}
export function EditOffer({ formData, onChange }) {
  return (
    <div>
      <Box>
        <Typography variant={"h5"}>Create/Edit Offer</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <div id="edit-offer-main">
        <TextField
          id="name"
          label="Offer Name"
          type="string"
          style={{ width: "100%", marginTop: 0, paddingTop: 0 }}
          value={formData.name}
          onChange={(e) => {
            updateOffer(formData, "name", e.target.value, onChange);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <div id="create-offer-dates">
          <TextField
            id="startDate"
            label="Start Date"
            type="date"
            value={formData.startDate}
            onChange={(e) => {
              updateOffer(formData, "startDate", e.target.value, onChange);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            id="date"
            label="Cut-Off Date"
            type="date"
            value={formData.cutOffDate}
            onChange={(e) => {
              updateOffer(formData, "endDate", e.target.value, onChange);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="offer-checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.active}
                onChange={() => {
                  updateOffer(formData, "active", !formData.active, onChange);
                }}
              />
            }
            label="Offer Active"
          />
        </div>
        <EditOfferProgramArray
          formData={formData.programs}
          onChange={updatePrograms(formData, onChange)}
        />
      </div>
    </div>
  );
}
