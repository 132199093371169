import { v4 as uuidv4 } from "uuid";
export function addNewPricingTable(
  handleChange,
  formData,
  companyId,
  setEditingPriceTableId,
  handleGoToNextStep
) {
  return async () => {
    const newTableId = uuidv4();
    await handleChange({
      ...(formData ? formData : {}),
      [newTableId]: {
        id: newTableId,
        name: "new price table",
        year: 2020,
        prePayDiscount: 0,
        conditions: [],
        priceTableCompanyId: companyId,
        serviceType: "",
        priceRows: []
      }
    });
    setEditingPriceTableId(newTableId);
    handleGoToNextStep("edit-price-table", "edit-upload-price-table");
  };
}
