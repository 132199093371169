import React, { useEffect, useState } from "react";
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data";
function setServiceType(setSelectedServiceType, handleChange) {
  return (e) => {
    handleChange(e.target.value);
    setSelectedServiceType(e.target.value);
  };
}
export function ServiceTypeSelector({ formData, onChange }) {
  const { serviceTypes = [] } = GetOnboardingData();

  const [selectedServiceType, setSelectedServiceType] = useState("");
  useEffect(() => {
    setSelectedServiceType(formData);
  }, [formData]);
  const menuItems = serviceTypes.map((serviceType, i) => {
    return (
      <MenuItem value={serviceType.name} key={i}>
        {serviceType.name}
      </MenuItem>
    );
  });
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id="programType">Service Type</InputLabel>
      <Select
        value={selectedServiceType}
        error={!selectedServiceType}
        helperText={"Required."}
        labelid="programType"
        onChange={setServiceType(setSelectedServiceType, onChange)}
        title="Service Type"
      >
        {menuItems}
      </Select>
    </FormControl>
  );
}
