import React from "react";
import { Box, Typography, TextField } from "@material-ui/core";
import "./company.css";
import { CurrencyField } from "./individual-fields/CurrencyField.jsx";
import { GetOnboardingData } from "../../../context/onboarding-data";
import { useState } from "react";
import { useEffect } from "react";
export function CompanyGeneralPricing({ formData, onChange }) {
  const { isRealGreen } = GetOnboardingData();

  const [state, setState] = useState(formData);
  function updateCompany(field, value) {
    setState((prev) => {
      return {
        ...prev,
        [field]: value
      };
    });
    onChange({ ...formData, [field]: value });
  }

  useEffect(() => {
    if (formData) {
      setState(formData);
    }
  }, [formData]);
  return (
    <div>
      <Box>
        <Typography variant={"h5"}>Company Pricing</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <div id="edit-company-main">
        <div className="company-info-row">
          <CurrencyField
            formData={state.currency}
            onChange={(currency) => updateCompany("currency", currency)}
          />
        </div>
        <TextField
          id="max-quotable-size"
          label="Max Quotable Lot Size (sq ft)"
          type="number"
          error={
            !state.maxQuotableSize ||
            state.maxQuotableSize < state.minQuotableSize
          }
          helperText={"Must be greater than min size."}
          value={state.maxQuotableSize}
          style={{ marginRight: 20 }}
          onChange={(e) => {
            updateCompany(
              "maxQuotableSize",
              e.target.value ? parseInt(e.target.value) : ""
            );
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="min-quotable-size"
          label="Min Quotable Lot Size (sq ft)"
          type="number"
          value={state.minQuotableSize}
          style={{ marginRight: 20 }}
          onChange={(e) => {
            updateCompany(
              "minQuotableSize",
              e.target.value ? parseInt(e.target.value) : ""
            );
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="terms-of-service"
          label="Terms Of Service"
          type="text"
          placeholder={`Please enter your terms of service. Example: 

"You will receive a Service Summary email/invoice after each application. Your services will renew each year and you will be notified in advance of any changes to your service for the next year. You will also have the opportunity to pay in advance for all scheduled services for the coming year at a discounted price. Fell free to call us anytime at ***.***.**** or email us at contact@real-business.com if you have any questions"`}
          multiline
          fullWidth
          value={state.termsOfService}
          rows={20}
          InputProps={{ disableUnderline: true }}
          onChange={(e) => {
            updateCompany("termsOfService", e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </div>
    </div>
  );
}
