// import moment from "moment";
import _ from "lodash";
import { API } from "aws-amplify";
const lawnbotPricing = require("lawnbot-pricing-calculations");
const priceCalculations = lawnbotPricing.priceCalculations;

import { currencies } from "./currencies/currencies";
const toCapitalizedWords = (name) => {
  var words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
};

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export async function testNonRTPPricing(
  squareFeet,
  program = {},
  priceTables = {},
  offers = {},
  isRTP,
  customerId,
  company,
  bot = {},
  postalCode = ""
) {
  if (!isRTP && (!program.services || !program.services.length)) {
    return "Program has no services.";
  }
  const info = await API.post("dashboardapi", "/v1/test-pricing", {
    body: {
      company: company,
      priceTables: Object.values(priceTables),
      program,
      offers: Object.values(offers),
      estimate: squareFeet,
      customerId,
      lookupZipCodeTax: bot.lookupZipCodeTax,
      postalCode
    }
  });
  if (info && Array.isArray(info) && info.length) {
    const applicationPricing = getTestPricing(
      isRTP,
      info[0],
      company,
      "application",
      bot
    );
    const prePayPricing = getTestPricing(isRTP, info[0], company, "total", bot);
    return { applicationPricing, prePayPricing };
  } else {
    return "No services found";
  }
}

function getTestPricing(
  isRTP,
  program,
  company = {},
  term = "application",
  bot = {}
) {
  if (!isRTP) {
    program.realTime = false;
  } else {
    program.realTime = true;
  }
  program.label = "Application";
  let pricingString = "";
  let currentCurrency = "$";
  currencies.forEach((currency) => {
    if (currency.name === company.currency) {
      currentCurrency = currency.symbol;
    }
  });
  if (isRTP) {
    pricingString = lawnbotPricing.offers.createRTPText(
      program.services[term === "total" ? "prePay" : "application"],
      currentCurrency,
      term === "total"
    );
  } else if (program.offers.length) {
    pricingString = lawnbotPricing.offers.createOfferText(
      program.services[term === "total" ? "prePay" : "application"][0]
        .grossPrice,
      program.offers[0],
      program.applicationLabel,
      currentCurrency,
      term === "total" ? program.prePayDiscount : null
    );
  } else {
    pricingString = program.firstAppPrice
      ? `${currentCurrency}${program.firstAppPrice.toFixed(
          2
        )} for the 1st application, $${program.applicationPrice.toFixed(
          2
        )} for remaining applications`
      : `${currentCurrency}${
          program.services[term === "total" ? "prePay" : "application"][0]
            .grossPrice
        } per ${toCapitalizedWords(
          program.applicationLabel || "Application"
        )} ${
          term === "total" && program.prePayDiscount
            ? `with an additional ${program.prePayDiscount}% off for prepaying.`
            : ""
        }`;
  }

  let applications = program["applicationsRemaining"];

  const display = (
    <div style={{ marginBottom: 10 }}>
      <div style={{ paddingTop: 10 }}>
        <strong style={{ fontSize: 15 }}>
          {displayTotalAmount(
            [program],
            term,
            {
              autoPayPriceMethod: company.autoPayPriceMethod,
              autoPayMonthlyPricePeriod: company.autoPayMonthlyPricePeriod,
              serviceTax: bot.serviceTax,
              lookupZipCodeTax: bot.lookupZipCodeTax,
              currency: company.currency
            },
            currentCurrency
          )}
        </strong>
        <br />({applications} applications) {pricingString}
        <br />
        <br />
      </div>
    </div>
  );
  return { display };
}

export const displayTotalAmount = (selectedItems, term, company, currency) => {
  const summary = priceCalculations.getSummaryPricing(
    selectedItems,
    term,
    company
  );
  let t = summary.tax;
  if (isNaN(t)) {
    return (
      <div>
        <span>
          Cost - {currency}
          {summary.price.toFixed(2)}
        </span>
        <span style={{ color: "red", fontSize: 14, fontWeight: "bold" }}>
          {` ${t}`}
        </span>
      </div>
    );
  } else {
    return (
      <div>
        Cost - {currency}
        {(summary.price + t).toFixed(2)}
      </div>
    );
  }
};
