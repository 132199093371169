import React from "react";
import { Box, Typography, TextField } from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data";
import { useState } from "react";
import { useEffect } from "react";
export function BrandingStartPage({ formData, onChange }) {
  const [state, setState] = useState(formData);
  function updateBranding(field, value) {
    setState((prev) => {
      return {
        ...prev,
        [field]: value
      };
    });
    onChange({ ...formData, [field]: value });
  }

  useEffect(() => {
    if (formData) {
      setState(formData);
    }
  }, [formData]);
  return (
    <div>
      <Box>
        <Typography variant={"h5"}>Company Branding</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <div>
        <TextField
          id="tag-line"
          label="Tag Line"
          type="text"
          value={state.tagLine}
          style={{ marginBottom: 20, marginTop: 20 }}
          fullWidth
          onChange={(e) => {
            updateBranding("tagLine", e.target.value ? e.target.value : "");
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="value-add-description"
          label="Business Description"
          type="text"
          multiline
          fullWidth
          value={state.valueAddDescription}
          onChange={(e) => {
            updateBranding("valueAddDescription", e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </div>
    </div>
  );
}
