import React, { Fragment, useEffect, useState } from "react";
import { TableCell, Select, MenuItem, TextField } from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data";
function updatePriceRow(item, field, value, updateForm) {
  updateForm({ ...item, [field]: value });
}
export function ServiceRow({ formData, onChange }) {
  const [item, setItem] = useState(formData);
  const data = GetOnboardingData();
  const rounds = [...Array(53).keys()];
  rounds.shift();
  useEffect(() => {
    setItem(formData);
  }, [formData]);
  return (
    <Fragment>
      <TableCell>
        <TextField
          id="serviceName"
          label="Service Name"
          value={item.serviceName}
          style={{ margin: 8, width: 100 }}
          placeholder="Mowing Service"
          margin="normal"
          onChange={(e) =>
            updatePriceRow(item, "serviceName", e.target.value, onChange)
          }
          InputLabelProps={{
            shrink: true
          }}
          validators={["required"]}
        />
      </TableCell>
      <TableCell>
        <Select
          labelid="roundNumber"
          id="roundNumber"
          style={{ marginTop: 16, width: 100 }}
          value={item.roundNumber}
          onChange={(e) =>
            updatePriceRow(item, "roundNumber", e.target.value, onChange)
          }
          validators={["required"]}
        >
          {rounds.map((i) => {
            const key = "menu-item-" + Math.floor(Math.random() * 100000);
            return (
              <MenuItem key={key} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell>
        <Select
          labelid="priceTableId"
          id="priceTableId"
          required={true}
          error={!item.priceTableId}
          helperText="No price table selected."
          style={{ marginTop: 16, width: 150 }}
          value={item.priceTableId}
          onChange={(e) =>
            updatePriceRow(item, "priceTableId", e.target.value, onChange)
          }
          validators={["required"]}
        >
          {data.priceTables &&
            Object.keys(data.priceTables).map((i) => {
              const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (
                <MenuItem key={key} value={data.priceTables[i].id}>
                  {data.priceTables[i].name}
                </MenuItem>
              );
            })}
        </Select>
      </TableCell>
      <TableCell>
        <TextField
          id="date"
          label="Cut-Off Date"
          type="date"
          error={!item.cutOffDate}
          style={{ marginTop: 23, paddingTop: 0, width: 150 }}
          value={item.cutOffDate}
          helperText={"Required."}
          onChange={(e) =>
            updatePriceRow(item, "cutOffDate", e.target.value, onChange)
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      </TableCell>
    </Fragment>
  );
}
