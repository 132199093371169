import React from "react";
import {
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { BrandingImage } from "../branding";
import * as icons from "@fortawesome/free-solid-svg-icons";
function reorderOption(
  item,
  optionIndex,
  optionIndexToSwap,
  updateForm,
  setState
) {
  let newOptionArray = [...item.options];
  const firstOption = { ...newOptionArray[optionIndex] };
  const secondOption = { ...newOptionArray[optionIndexToSwap] };
  newOptionArray[optionIndex] = secondOption;
  newOptionArray[optionIndexToSwap] = firstOption;
  const updatedObject = {
    ...item,
    options: [...newOptionArray]
  };
  setState(updatedObject);
  updateForm(updatedObject);
}

function removeOption(question, indexOfOption, updateForm, setState) {
  const copyArray = [...question.options];
  copyArray.splice(indexOfOption, 1);
  const updatedObject = {
    ...question,
    options: copyArray
  };
  setState(updatedObject);
  updateForm(updatedObject);
}

function updateOptions(item, optionField, value, index, updateForm, setState) {
  let newOptionArray = [...item.options];
  newOptionArray[index] = { ...newOptionArray[index], [optionField]: value };
  const updatedObject = {
    ...item,
    options: [...newOptionArray]
  };
  setState(updatedObject);
  updateForm(updatedObject);
}

export function QuestionOptions({ onChange, item, setItem }) {
  return (
    <div className="question-options-inner">
      {item.options.map((option, index) => {
        return (
          <div
            className="question-option-row"
            style={{ display: "flex", alignItems: "center" }}
            key={`question-option-${index}`}
          >
            <div id="question-option">
              <div className="image-field-container">
                {item.type === "image-text-option" ? (
                  <div className="image-option-holder">
                    <BrandingImage
                      field={"image"}
                      aspect={1 / 1}
                      imageButtonClassName="image-option-button"
                      fieldName={"image"}
                      inputStyle={{ display: "none" }}
                      inputLabel={
                        <InputLabel
                          id={`image-url-input-label`}
                          className="image-input-label"
                          htmlFor={`image-url-input`}
                        >
                          <i className="fas fa-camera"></i>
                        </InputLabel>
                      }
                      useHeader={false}
                      allowableContentType={"image/*"}
                      image={option["image"]}
                      formData={option}
                      imageStyle={{
                        padding: 0,
                        height: 33,
                        width: 33
                      }}
                      handleChange={(newOption) =>
                        updateOptions(
                          item,
                          "image",
                          newOption["image"],
                          index,
                          onChange,
                          setItem
                        )
                      }
                    />
                  </div>
                ) : null}
                {item.type === "icon-text-option" ? (
                  <Select
                    id="fa-icon"
                    value={option["iconClass"]}
                    onChange={(e) =>
                      updateOptions(
                        item,
                        "iconClass",
                        e.target.value,
                        index,
                        onChange,
                        setItem
                      )
                    }
                    disableUnderline={true}
                    validators={["required"]}
                  >
                    {icons &&
                      Object.values(Object.values(icons)[0]).map((i) => {
                        const key =
                          "menu-item-" + Math.floor(Math.random() * 100000);
                        return (
                          <MenuItem key={key} value={i.iconName}>
                            <i className={`${i.prefix} fa-${i.iconName}`}></i>
                          </MenuItem>
                        );
                      })}
                  </Select>
                ) : null}

                <TextField
                  placeholder={"option text"}
                  value={option.text}
                  margin="normal"
                  className="option-text"
                  onChange={(e) => {
                    updateOptions(
                      item,
                      "text",
                      e.target.value,
                      index,
                      onChange,
                      setItem
                    );
                  }}
                  InputProps={{
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  validators={["required"]}
                />
              </div>
              <Button
                variant="text"
                className=""
                onClick={() => {
                  removeOption(item, index, onChange, setItem);
                }}
              >
                <i className="fas fa-minus-circle remove-button"></i>
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: 15
              }}
            >
              <Button
                style={{ minWidth: 32 }}
                disabled={index === 0}
                variant="text"
                onClick={() => {
                  reorderOption(item, index, index - 1, onChange, setItem);
                }}
              >
                <i className="fas fa-arrow-up"></i>
              </Button>
              <Button
                style={{ minWidth: 32 }}
                disabled={index === item.options.length - 1}
                variant="text"
                onClick={() => {
                  reorderOption(item, index, index + 1, onChange, setItem);
                }}
              >
                <i className="fas fa-arrow-down"></i>
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
