import React, { useState, useEffect } from "react";
import { Typography, TextField, Box } from "@material-ui/core";
import "./objections.css";
function updateObjection(objections, field, value, updateForm, setState) {
  const newObject = {
    ...objections,
    [field]: { type: objections[field].type, question: value }
  };
  setState(newObject);
  updateForm(newObject);
}

export function Objections({ formData, onChange }) {
  const [objections, setObjections] = useState(null);
  useEffect(() => {
    if (formData && !objections) {
      setObjections(formData);
      onChange(formData);
    }
  }, [formData, objections, onChange]);
  return objections ? (
    <div>
      <Box>
        <Typography style={{ marginBottom: 20 }} variant={"h5"}>
          Objections
        </Typography>
        <hr className="MuiDivider-root" />
      </Box>
      <div id="objections">
        {" "}
        <TextField
          id="objections-people"
          className="text-field"
          label="People"
          type="text"
          multiline
          fullWidth
          value={formData.people.question}
          rows={10}
          onChange={(e) => {
            updateObjection(
              objections,
              "people",
              e.target.value,
              onChange,
              setObjections
            );
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="objection-price"
          className="text-field"
          label="Price"
          type="text"
          multiline
          fullWidth
          value={formData.price.question}
          rows={10}
          onChange={(e) => {
            updateObjection(
              objections,
              "price",
              e.target.value,
              onChange,
              setObjections
            );
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="objection-product"
          className="text-field"
          label="Product"
          type="text"
          multiline
          fullWidth
          value={formData.product.question}
          rows={10}
          onChange={(e) => {
            updateObjection(
              objections,
              "product",
              e.target.value,
              onChange,
              setObjections
            );
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </div>
    </div>
  ) : null;
}
