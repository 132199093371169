import React, { useState, useEffect, useRef, useContext } from "react";
import { Auth } from "aws-amplify";
import { Button, Typography, Box } from "@material-ui/core";
const DevPanel = ({ companyId = "test", companyName = "test" }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div
        style={{ borderRadius: 20, padding: 20 }}
        className={`dev-container dev-container-${
          expanded ? "expanded" : "collapsed"
        }`}
      >
        <Box style={{ marginBottom: 20 }}>
          <Typography variant={"h5"}>Lawnbot Employee Tools</Typography>
          <hr className="MuiDivider-root" />
        </Box>
        <div>
          <p>Dominate Link: </p>
          <a
            href={`dominate.lawnbot.biz/company/${companyId}`}
            target="_blank"
            rel="noreferrer"
          >
            {companyName}
          </a>
        </div>
        <Button
          color="primary"
          variant="contained"
          className="expand-button"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "<" : ">"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            width: 100,
            margin: "auto",
            bottom: 30
          }}
          onClick={(e) => {
            e.preventDefault();
            e.bubbles = false;

            Auth.signOut().then((val) => {
              window.location = "";
            });
          }}
        >
          <span>Signout</span>
        </Button>
      </div>
    </>
  );
};

export default DevPanel;
