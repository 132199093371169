import { toast } from "react-toastify";
import { API } from "aws-amplify";
export const createImage = async (
  filename,
  path,
  contentType = "image/*",
  ext,
  toastId,
  fieldName
) => {
  const payload = { aboutUsAssetType: contentType };
  const data = {
    filename,
    file: path,
    contentType,
    ext
  };
  try {
    toastId.current = toast(`Uploading ${fieldName} file...`);
    const result = await API.post("dashboardapi", "/v1/onboarding-branding", {
      body: { data }
    });
    if (!result.error) {
      toast.update(toastId.current, {
        render: `${fieldName} saved`,
        type: toast.TYPE.INFO,
        autoClose: 3000,
        className: "rotateY animated"
      });
      return result.message;
    }
  } catch (error) {
    toast.update(toastId.current, {
      render: `Error uploading ${fieldName} file`,
      type: toast.TYPE.INFO,
      autoClose: 3000,
      className: "rotateY animated"
    });
    return false;
  }
};
