import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import { PriceRow } from ".";
import CSVReader from "react-csv-reader";
import { Container } from "react-bootstrap";
const cvsUploadPriceRows = (data = [], rowsArray, updateForm) => {
  const newArray = [...rowsArray];
  data.shift();
  data.forEach((r) => {
    const p = Object.assign(
      {},
      {
        start: parseFloat(r[0] || 0),
        end: parseFloat(r[1] || 0),
        price: parseFloat(r[2] || 0),
        units: r[3]
      }
    );
    newArray.push(p);
  });

  updateForm(newArray);
};

function handleUpdatePriceRow(rowArray, newRow, index, updateForm) {
  const newArray = rowArray.map((row, i) => {
    return i === index ? newRow : row;
  });
  updateForm(newArray);
}

function addNewRow(rowArray, updateForm) {
  const newArray = [
    ...rowArray,
    { start: 0, end: 0, units: "1000 sqft", price: 0 }
  ];
  updateForm(newArray);
}

function removeRow(rowArray, indexToRemove, updateForm) {
  const newArray = [...rowArray];
  if (indexToRemove > -1 && newArray.length > indexToRemove) {
    newArray.splice(indexToRemove, 1);
    updateForm(newArray);
  }
}

export function PriceRows({ formData = [], onChange }) {
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [priceRows, setPriceRows] = useState(formData);
  useEffect(() => {
    setPriceRows(formData);
  }, [formData]);
  return (
    <Fragment>
      <Typography style={{ marginBottom: 20 }} variant={"h5"}>
        PriceRows
      </Typography>
      <div style={{ overflow: "scroll", maxHeight: 370 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priceRows.map((element, index) => {
              return (
                <TableRow key={index}>
                  <PriceRow
                    formData={element}
                    onChange={(newRow) =>
                      handleUpdatePriceRow(formData, newRow, index, onChange)
                    }
                  />
                  <TableCell>
                    <Button
                      onClick={() => removeRow(formData, index, onChange)}
                      color="secondary"
                    >
                      X
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      <div style={{ position: "relative", marginTop: 70 }}>
        <hr style={{ borderWidth: 5 }} />
        <div
          style={{
            position: "absolute",
            textAlign: "center",
            margin: "auto",
            width: "100%",
            top: -10
          }}
        >
          <span
            style={{
              backgroundColor: "white",
              padding: 15,
              fontWeight: "bolder",
              fontSize: 20
            }}
          >
            OR
          </span>
        </div>
      </div>
      <Container
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
          alignItems: "center"
        }}
      >
        <div style={{ textAlign: "left" }}>
          <a href="/pricetable-template.csv">Download Price Table Template</a>
          <br />
          <br />
          <CSVReader
            cssClass="react-csv-input"
            label="Upload Pricing Info From CSV"
            onFileLoaded={async (data) => {
              setLoadingCSV(true);
              cvsUploadPriceRows(data, formData, onChange);
              document.querySelector(".csv-input").value = "";
            }}
            inputStyle={{
              display: "block",
              margin: "15px auto",
              borderRadius: 5
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            disable={loadingCSV}
            variant="contained"
            color={"primary"}
            onClick={() => addNewRow(formData, onChange)}
          >
            Add Price Row
          </Button>
          <Button
            disable={loadingCSV}
            variant="contained"
            style={{ marginTop: 10 }}
            color={"secondary"}
            onClick={() => onChange([])}
          >
            Clear All Rows
          </Button>
        </div>
      </Container>
    </Fragment>
  );
}
