export function getRTPDiscountSteps() {
  return {
    "edit-program-type-of-discount": {
      formDataKey: "programs",
      nextStepComparison: {
        field: "usingApplicationDiscounts",
        value: true,
        operator: "==="
      },
      nextStepOptions: [
        "edit-program-using-pre-pay",
        "edit-program-application-discounts" // or edit offers if not rtp
      ],
      editing: "editingProgramId",
      propertyToEdit: "programs"
    },
    "edit-program-application-discounts": {
      formDataKey: "programs",
      nextStepOptions: ["edit-program-using-pre-pay"],
      editing: "editingProgramId",
      propertyToEdit: "programs"
    },
    "edit-program-using-pre-pay": {
      formDataKey: "programs",
      nextStepComparison: {
        field: "usingRTPPrePay",
        value: true,
        operator: "==="
      },
      nextStepOptions: ["test-pricing", "edit-program-prepay-discount"],
      editing: "editingProgramId",
      propertyToEdit: "programs"
    },
    "edit-program-prepay-discount": {
      formDataKey: "programs",
      nextStepOptions: ["test-pricing"],
      editing: "editingProgramId",
      propertyToEdit: "programs"
    }
  };
}
