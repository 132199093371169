import React from "react";

const OnboardingDataContext = React.createContext(null);

const GetOnboardingData = () => React.useContext(OnboardingDataContext);

const OnboardingDataProvider = (value, children) => {
  return (
    <OnboardingDataContext.Provider value={value}>
      {children}
    </OnboardingDataContext.Provider>
  );
};
export { OnboardingDataProvider, GetOnboardingData };
