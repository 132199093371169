import { PricingTables } from "../../step-component/custom-steps/pricing-tables";
import { PriceRows } from "../../step-component/custom-steps/pricing-tables/price-rows";
export function getPriceTableUI() {
  return {
    "edit-upload-price-table": () => {
      return {
        "ui:FieldTemplate": PricingTables
      };
    },
    "edit-price-table": () => {
      return {
        priceRows: {
          "ui:FieldTemplate": PriceRows,
          "ui:title": "Price Rows"
        }
      };
    }
  };
}
