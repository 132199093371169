import {
  faMoneyBillWaveAlt,
  faPencilAlt,
  faTag,
  faTags,
  faThList
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

export const offerSchema = {
  "edit-ask-if-using-offers": {
    type: "object",
    title: "Will you be setting up special offers?",
    properties: {
      usingOffers: { type: "boolean", default: false, title: "Use Offers" }
    },
    icon: faMoneyBillWaveAlt,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Offers Help Your Customers Save!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Lawnbot allows you to offer discounts to your customers on a:
          <ul style={{ textAlign: "left", marginLeft: "10%" }}>
            <li>Application Level</li>
            <li>Program Level</li>
          </ul>
          <br />
        </Typography>
      </div>
    )
  },
  "edit-offers-table": {
    type: "object",
    title: "Offers",
    icon: faTags,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Here&apos;s Your Offers!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          On this screen you can create a new offer or apply this program to an
          already existing offer by clicking the Edit button.
        </Typography>
      </div>
    )
  },
  "edit-offer": {
    type: "object",
    title: "Create/Edit Offer",
    navigationButtons: ["Back to Offers Page", null],
    properties: {
      name: { type: "string", default: "new offer" },
      startDate: { type: "string" },
      endDate: { type: "string" },
      active: { type: "boolean", title: "Offer Active", default: true },
      programs: {
        type: "array",
        default: [],
        items: {
          type: "object",
          properties: {
            programId: { type: "string" },
            discountType: { type: "string" },
            appliedTo: { type: "string" },
            discountAmount: { type: "number", default: 0 }
          }
        }
      }
    },
    icon: faTag,
    sidebarDescription: (
      <div>
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Here&apos;s Your Offers!
        </Typography>
        <hr />
        <Typography
          paragraph={true}
          key={"description_" + Math.random()}
          className="step-description"
        >
          Edit an Offer.
          <br />
          <ul style={{ textAlign: "left", marginLeft: "10%" }}>
            <li>
              <u>Name</u>- What is the name of your offer?{" "}
            </li>
            <li>
              <u>Start Date</u>- When does your offer start?{" "}
            </li>
            <li>
              <u>End Date</u>- When does your offer end?{" "}
            </li>
            <li>
              <u>Active</u>- Toggle On/Off your offer{" "}
            </li>
            <li>
              <u>Programs</u> - All of the programs you want the offer to be
              applied to.
            </li>
            <li>
              <u>Discount Types</u> - You can create 3 types of discounts.
              Percentage based (%) where the value is a percent off of the
              original price, Fixed price where the value is a fixed amount
              subtracted from the original price, and Discounted Price Table
              where you may use a price table setup in an earlier step to
              determine a new pricing structure for the program
            </li>
            <li>
              <u>Discount Applied To</u> - You may decide whether to add the
              discount to the entire program or just the first service provided
              to the customer.
            </li>
          </ul>
        </Typography>
      </div>
    )
  }
};
