import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data";

function getDisplay(type, option, index) {
  let value = {};
  switch (type) {
    case "default":
      value = (
        <div className="option-text-holder">
          <div className="option text-option">{option.text}</div>
        </div>
      );
      break;
    case "image-text-option":
      value = (
        <div
          className="option image-option"
          style={{
            backgroundImage: `url(${option["image"]})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
            overflow: "hidden"
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              backgroundColor: "#117efb",
              color: "white",
              padding: 5,
              width: "105%",
              left: -8,
              height: "auto"
            }}
          >
            {option.text}
          </div>
        </div>
      );
      break;
    case "icon-text-option":
      value = (
        <div className="option icon-option">
          <i className={`fas fa-${option["iconClass"]}`}></i>
          <div className="option-text-holder">{option.text}</div>
        </div>
      );
      break;
    case "freeform":
      value = (
        <div style={{ width: 300, margin: "auto" }}>
          <input
            type="text"
            className="preview-free-form"
            placeholder="type your answer here"
          />
          <div className="free-form-instructions">
            * please type your answer
          </div>
        </div>
      );
      break;
    default:
      break;
  }
  return value;
}

export function QuestionPreview({ item }) {
  const data = GetOnboardingData();
  return (
    <div id="question-preview">
      <Typography style={{ marginBottom: 20 }} variant={"h5"}>
        Question Preview
      </Typography>
      <div className="question-holder">
        <div className="avatar-holder">
          <img
            src="https://lawnbotcompanystorage161827-dev.s3.amazonaws.com/public/d8781db9-48f7-4a30-aa55-0d959b5c5d71/avatar.jpeg"
            alt="avatar"
          />
        </div>
        <div className="bubble">{item.question}</div>
      </div>
      {item.options && item.options.length ? (
        <Grid container spacing={3}>
          {item.options.map((option, index) => (
            <Grid
              item
              xs={3}
              className={"option-preview"}
              key={`preview-option-${index}`}
            >
              {getDisplay(item.type, option, index)}
            </Grid>
          ))}
        </Grid>
      ) : (
        getDisplay(item.type, [], 0)
      )}
    </div>
  );
}
