export function getProgramSteps(isRealGreen) {
  return {
    "program-page": {
      formDataKey: "programs",
      nextStepOptions: ["branding-start"]
    },
    "edit-program": {
      formDataKey: "programs",
      editing: "editingProgramId",
      propertyToEdit: "programs",
      nextStepOptions: [
        isRealGreen ? "edit-program-using-rtp" : "edit-upload-price-table"
      ]
    },
    // if Real Green, are we using real time pricing?
    ...(isRealGreen
      ? {
          "edit-program-using-rtp": {
            // only if real green
            formDataKey: "programs",
            nextStepComparison: {
              field: "useRealGreenPricingEngine",
              value: true,
              operator: "==="
            },
            nextStepOptions: [
              "edit-upload-price-table",
              "edit-program-type-of-discount"
            ],
            editing: "editingProgramId",
            propertyToEdit: "programs"
          },
          // are we applying discounts
          "edit-program-discounts-question": {
            formDataKey: "programs",
            nextStepComparison: {
              field: "usingDiscounts",
              value: true,
              operator: "==="
            },
            nextStepOptions: ["test-pricing", "edit-program-type-of-discount"],
            editing: "editingProgramId",
            propertyToEdit: "programs"
          }
        }
      : {})
  };
}
