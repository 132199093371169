import { PrePayDropdowns } from "../../step-component/custom-steps/PrePayDropdowns.jsx";
export function getRTPDicsountUI() {
  return {
    "edit-program-prepay-discount": () => {
      return {
        realGreenPrepayDiscountCode: { "ui:FieldTemplate": PrePayDropdowns }
      };
    }
  };
}
