export function getOfferSteps() {
  return {
    "edit-ask-if-using-offers": {
      formDataKey: "programs",
      nextStepComparison: {
        field: "usingOffers",
        value: true,
        operator: "==="
      },
      nextStepOptions: ["edit-program-services", "edit-offers-table"],
      editing: "editingProgramId",
      propertyToEdit: "programs"
    },
    "edit-offers-table": {
      formDataKey: "offers",
      nextStepOptions: ["edit-program-services"]
    },
    "edit-offer": {
      formDataKey: "offers",
      nextStepOptions: ["edit-offers-table"],
      editing: "editingOfferId",
      propertyToEdit: "offers",
      recordNextStep: false
    }
  };
}
