import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Snackbar
} from "@material-ui/core";
import "./company.css";
import { GetOnboardingData } from "../../../context/onboarding-data";
import { API } from "aws-amplify";
export function CreateTestClient({ onChange, formData }) {
  const { realGreenApiKey, testCustomerId, setTestCustomerId } =
    GetOnboardingData();
  const [clientData, setClientData] = useState({
    name: "Do Not Service",
    customerZip: "",
    customerAddress: "",
    email: "testing@lawnbot.biz",
    phonenumber: "6155551212",
    sourceCode: formData.marketingSource,
    callActionReason: formData.callActionReason,
    lawnbotEmployee: formData.lawnbotEmployee,
    comp_id: formData.crmCompanyId || "-1"
  });
  const [snackMessage, setSnackMessage] = useState("Settings Saved");
  const [snackOpened, openSnack] = useState(false);
  useEffect(() => {
    if (formData) {
      setClientData((prev) => {
        return {
          ...prev,
          sourceCode: formData.marketingSource,
          callActionReason: formData.callActionReason,
          lawnbotEmployee: formData.lawnbotEmployee
        };
      });
    }
  }, [formData, setClientData]);

  async function createTestCustomer() {
    try {
      const rgCustomer = await API.post(
        "dashboardapi",
        "/v1/onboarding/create-test-customer",
        {
          body: {
            rgAttributes: { ...formData, apiKey: realGreenApiKey },
            customerPayload: clientData
          }
        }
      ).catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          throw Error(err.response.data.message);
        } else {
          throw Error("Unauthorized.");
        }
      });
      if (!rgCustomer.error && rgCustomer.message) {
        setSnackMessage("Test User Created.");
        onChange({ ...formData, testCustomerId: rgCustomer.message });
        setTestCustomerId(rgCustomer.message);
      }
    } catch (err) {
      setSnackMessage(`${err}`);
    }
    openSnack(true);
  }
  return (
    <div>
      <Box>
        <Typography variant={"h5"}>Create Test Customer</Typography>
        <hr className="MuiDivider-root" />
      </Box>
      {formData.testCustomerId ? (
        <div
          style={{
            minHeight: 400,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column"
          }}
        >
          <Typography variant={"p"}>Test Customer Already Created</Typography>
        </div>
      ) : (
        <Fragment>
          <TextField
            id="testName"
            label="Customer Name"
            disabled={true}
            value={clientData.name}
            style={{ margin: 8, width: "100%", marginTop: 25 }}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={[]}
            onChange={(e) => {
              setClientData((prev) => {
                return { ...prev, name: e.target.value };
              });
            }}
          />
          <TextField
            id="testAddress"
            label="Customer Street Address"
            value={clientData.customerAddress}
            style={{ margin: 8, width: "100%", marginTop: 25 }}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={[]}
            onChange={(e) => {
              setClientData((prev) => {
                return { ...prev, customerAddress: e.target.value };
              });
            }}
          />
          <TextField
            id="testZip"
            label="Customer Zip"
            value={clientData.customerZip}
            style={{ margin: 8, width: "100%", marginTop: 25 }}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={[]}
            onChange={(e) => {
              setClientData((prev) => {
                return { ...prev, customerZip: e.target.value };
              });
            }}
          />
          <TextField
            id="testEmail"
            label="Customer Email"
            disabled={true}
            value={clientData.email}
            style={{ margin: 8, width: "100%", marginTop: 25 }}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={[]}
            onChange={(e) => {
              setClientData((prev) => {
                return { ...prev, email: e.target.value };
              });
            }}
          />
          <TextField
            id="testPhone"
            label="Customer Phone Number"
            disabled={true}
            value={clientData.phonenumber}
            style={{ margin: 8, width: "100%", marginTop: 25 }}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            errorMessages={["this field is required"]}
            validators={[]}
            onChange={(e) => {
              setClientData((prev) => {
                return { ...prev, phonenumber: e.target.value };
              });
            }}
          />
          <Button
            onClick={createTestCustomer}
            disabled={
              Object.values(clientData).find((value) =>
                value ? false : true
              ) === undefined
                ? false
                : true
            }
            color="primary"
            variant="contained"
          >
            Create Test Client
          </Button>
          <Snackbar
            open={snackOpened}
            autoHideDuration={2000}
            onClose={() => {
              openSnack(false);
            }}
            message={snackMessage}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          />
        </Fragment>
      )}
    </div>
  );
}
