export function getPriceTableSteps(isRTP) {
  return {
    "edit-upload-price-table": {
      formDataKey: "priceTables",
      nextStepOptions: [isRTP ? "test-pricing" : "edit-ask-if-using-offers"]
    },
    "edit-price-table": {
      formDataKey: "priceTables",
      propertyToEdit: "priceTables",
      editing: "editingPriceTableId",
      recordNextStep: false,
      nextStepOptions: ["edit-upload-price-table"] //if not real green then just go to services
    }
  };
}
