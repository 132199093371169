import React from "react";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import { GetOnboardingData } from "../../../context/onboarding-data";
export function QuestionsPage({ onAddClick, items, title }) {
  const {
    goToNextStepFunc,
    setEditingQuestionIndex,
    setEditingServiceQuestionIndex
  } = GetOnboardingData();
  const questionList = items.map((item, index) => {
    return (
      <TableRow key={index} className={"program-row"}>
        <TableCell>{item.children.props.formData.question}</TableCell>
        <TableCell style={{ paddingLeft: 10 }}>
          <div style={{ display: "flex" }}>
            <Button
              style={{ minWidth: 32 }}
              disabled={index === 0}
              variant="text"
              onClick={item.onReorderClick(index, index - 1)}
            >
              <i className="fas fa-arrow-up"></i>
            </Button>
            <Button
              style={{ minWidth: 32 }}
              disabled={index === items.length - 1}
              variant="text"
              onClick={item.onReorderClick(index, index + 1)}
            >
              <i className="fas fa-arrow-down"></i>
            </Button>
          </div>
        </TableCell>
        <TableCell align="right">
          <Button
            color="primary"
            variant="text"
            onClick={async () => {
              await (title === "Bot Questions"
                ? setEditingQuestionIndex(index)
                : setEditingServiceQuestionIndex(index));
              goToNextStepFunc(
                ...(title === "Bot Questions"
                  ? ["edit-question", "questions-page"]
                  : ["edit-service-question", "serviceQuestions"])
              );
            }}
          >
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button color="secondary" onClick={item.onDropIndexClick(index)}>
            X
          </Button>
        </TableCell>
      </TableRow>
    );
  });
  return (
    <div>
      <Typography style={{ marginBottom: 20 }} variant={"h5"}>
        {title} List
      </Typography>
      <hr className="MuiDivider-root" />
      <Button
        style={{ float: "right", position: "relative", marginTop: 10 }}
        variant="text"
        onClick={async (e) => {
          await onAddClick(e);
          title === "Bot Questions"
            ? setEditingQuestionIndex(items.length)
            : setEditingServiceQuestionIndex(items.length);
          goToNextStepFunc(
            ...(title === "Bot Questions"
              ? ["edit-question", "questions-page"]
              : ["edit-service-question", "serviceQuestions"])
          );
        }}
      >
        <i
          style={{ color: "green", marginRight: 5 }}
          className="fas fa-plus-circle"
        ></i>
        Add Question
      </Button>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell align="left">Reorder</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{questionList}</TableBody>
      </Table>
    </div>
  );
}
