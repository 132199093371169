import React, { useState, useEffect, Fragment } from "react";
import StepWizard from "react-step-wizard";
import OnboardingStep from "./step-component/OnboardingStep.jsx";
import { CircularProgress } from "@material-ui/core";
import { getSteps } from "./step-definitions";
import { API } from "aws-amplify";
import { OnboardingDataProvider } from "./context/onboarding-data.js";
import DevPanel from "./DevPanel.jsx";
import {
  getFormToPassDown,
  handleStepFormChange,
  setRecordedNextStep,
  getCompanyDiscountCodes,
  getCompanyPrograms,
  getDefaultForm,
  getCompanyEmployees,
  getCompanyMarketingCodes,
  getCompanyCallActionReasons,
  getCompanyCallStatuses,
  getCompanies,
  getCompanyRoutes
} from "./onboarding-helpers.js";
// this needs to turn into a call to the backend to pull the json blob from the company
// use if to test data for now

async function saveData(data, step, editingKeys) {
  data.userIsOnStep = step;
  data.editingKeys = editingKeys;
  if (data.company) {
    await API.put("dashboardapi", "/v1/onboarding", {
      body: {
        onboardingJson: JSON.stringify(data)
      }
    });
  }
}

function Onboarding({ isLawnbotEmployee }) {
  // formData is the data being pulled from the saved json blob and is what is being saved for each step into the json blob
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingRG, setLoadingRG] = useState(true);
  const [rgZipCodes, setRGZipCodes] = useState([]);
  const [initialUserIsOnStep, setInitialUserIsOnStep] = useState(null);
  const [companyId, setCompanyId] = useState("");
  const [isRealGreen, setIsRealGreen] = useState(false);
  const [realGreenApiKey, setRealGreenApiKey] = useState("");
  const [testCustomerId, setTestCustomerId] = useState("");
  const [rgPrograms, setRealGreenPrograms] = useState([]);
  const [rgCodes, setRGCodes] = useState([]);
  const [editingProgramId, setEditingProgramId] = useState("");
  const [editingPriceTableId, setEditingPriceTableId] = useState("");
  const [editingOfferId, setEditingOfferId] = useState("");
  const [error, setError] = useState(false);
  const [editingServiceQuestionIndex, setEditingServiceQuestionIndex] =
    useState("");
  const [editingQuestionIndex, setEditingQuestionIndex] = useState("");
  const [goToNextStepFunc, setGoToNextStepFunc] = useState(null);
  const [serviceTypes, setServiceTypes] = useState([]);
  // Real Green Stuff
  const [employees, setEmployees] = useState([]);
  const [marketingCodes, setMarketingCodes] = useState([]);
  const [callReasons, setCallReasons] = useState([]);
  const [callStatuses, setCallStatuses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const fn = async () => {
      const client = await API.get("dashboardapi", "/v1/onboarding", {});
      const serviceTypes = await API.get(
        "dashboardapi",
        "/v1/service-types",
        {}
      );
      if (
        !serviceTypes.error &&
        serviceTypes.message &&
        Array.isArray(serviceTypes.message)
      ) {
        setServiceTypes(serviceTypes.message);
      }
      if (!client.error) {
        if (client.message) {
          if (client.message.companyId) {
            setCompanyId(client.message.companyId);
          }

          if (client.message.crmSolution === "real-green") {
            setIsRealGreen(true);
          }

          if (
            client.message.onboardingJson &&
            JSON.parse(client.message.onboardingJson)
          ) {
            const data = JSON.parse(client.message.onboardingJson);
            const { editingKeys } = data;
            const {
              editingOfferId,
              editingPriceTableId,
              editingProgramId,
              editingQuestionIndex,
              editingServiceQuestionIndex
            } = editingKeys || {};
            setEditingOfferId(editingOfferId);
            setEditingPriceTableId(editingPriceTableId);
            setEditingProgramId(editingProgramId);
            setEditingQuestionIndex(editingQuestionIndex);
            setFormData(data);
            setTestCustomerId(data.company.testCustomerId);
            setEditingOfferId(editingOfferId);
            setEditingServiceQuestionIndex(editingServiceQuestionIndex);
            if (loading && data.userIsOnStep) {
              setInitialUserIsOnStep(data.userIsOnStep);
            }
          } else {
            const {
              customerName,
              companyName,
              customerEmail,
              companyId,
              customerPhone,
              customerAddress,
              customerCity,
              customerState,
              customerZip
            } = client.message;
            setFormData({
              company: {
                id: companyId,
                customerEmail,
                customerName,
                companyName,
                customerPhone,
                customerAddress,
                customerCity,
                customerState,
                customerZip
              },
              ...getDefaultForm()
            });
          }
        }
        setLoading(false);
      } else {
        setError(true);
      }
    };
    if (loading) {
      fn();
    }
  }, [loading]);
  useEffect(() => {
    const fn = async () => {
      const company = await API.get("dashboardapi", "/v1/companys", {});
      if (
        company &&
        company.message &&
        company.message.data &&
        company.message.data.getCompanyVersioned &&
        company.message.data.getCompanyVersioned.dataServices
      ) {
        const apiKey =
          company.message.data.getCompanyVersioned.dataServices.realGreenAPIKey;
        if (apiKey) {
          const rgcodes = await getCompanyDiscountCodes(apiKey);
          const availableZips = await API.post(
            "dashboardapi",
            "/v1/real-green-zips",
            {
              body: {
                apiKey,
                companyId: company.message.data.getCompanyVersioned.id
              }
            }
          );
          const realGreenPrograms = await getCompanyPrograms(apiKey);
          if (!availableZips.error) {
            setRGZipCodes(availableZips.message);
          }

          setRGCodes(rgcodes);
          setRealGreenPrograms(realGreenPrograms);
          setRealGreenApiKey(apiKey);
          Promise.all([
            getCompanyEmployees(apiKey),
            getCompanyMarketingCodes(apiKey),
            getCompanyCallActionReasons(apiKey),
            getCompanyCallStatuses(apiKey),
            getCompanies(apiKey),
            getCompanyRoutes(apiKey)
          ]).then(
            ([
              lemployees,
              marketingcodes,
              callreasons,
              callstatuses,
              lcompanies,
              lroutes
            ]) => {
              setEmployees(lemployees);
              setMarketingCodes(marketingcodes);
              setCallReasons(callreasons);
              setCallStatuses(callstatuses);
              setCompanies(lcompanies);
              setRoutes(lroutes);
            }
          );
        }
      }
      setLoadingRG(false);
    };
    if (loadingRG) {
      fn();
    }
  }, [loadingRG]);

  if (!error) {
    const programs = formData.programs ? formData.programs : {};
    let stepData = getSteps(
      isRealGreen,
      editingProgramId,
      programs,
      testCustomerId
    );
    const editingKeys = {
      editingProgramId: editingProgramId,
      editingPriceTableId: editingPriceTableId,
      editingOfferId: editingOfferId,
      editingQuestionIndex: editingQuestionIndex,
      editingServiceQuestionIndex: editingServiceQuestionIndex
    };

    const steps = Object.keys(stepData).map((key, index) => {
      const {
        formDataKey,
        nextStepOptions,
        nextStepComparison,
        editing,
        propertyToEdit,
        recordNextStep,
        isArray
      } = stepData[key];

      const formToPassDown = formDataKey
        ? getFormToPassDown(
            formData,
            formDataKey,
            editing ? editingKeys[editing] : false,
            propertyToEdit
          )
        : {};
      return (
        <OnboardingStep
          key={`step-${index}`}
          initialUserIsOnStep={initialUserIsOnStep}
          setInitialUserIsOnStep={setInitialUserIsOnStep}
          isRealGreen={isRealGreen}
          loading={loading}
          isFirstStep={index === 0}
          recordNextStep={recordNextStep}
          stepTakenBookmarks={formData.nextStepBookmarks}
          isLastStep={index === Object.keys(stepData).length - 1}
          editingProgramId={editingProgramId}
          setEditingProgramId={setEditingProgramId}
          editingPriceTableId={editingPriceTableId}
          setEditingPriceTableId={setEditingPriceTableId}
          stepName={key}
          rgCodes={rgCodes}
          formData={
            Array.isArray(formToPassDown)
              ? [...formToPassDown]
              : { ...formToPassDown }
          }
          recordStepTaken={setRecordedNextStep(setFormData, key)}
          saveData={(stepName, newEditingKeys) =>
            saveData(
              formData,
              stepName,
              newEditingKeys ? newEditingKeys : editingKeys
            )
          }
          handleChange={handleStepFormChange(
            setFormData,
            formDataKey,
            key,
            editing ? editingKeys[editing] : false,
            propertyToEdit,
            isArray,
            saveData,
            editingKeys
          )}
          nextStepComparison={nextStepComparison}
          companyId={companyId}
          nextStepOptions={nextStepOptions}
        />
      );
    });

    return loading && !initialUserIsOnStep ? (
      <div
        style={{
          height: "100px",
          textAlign: "center",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto"
        }}
      >
        <CircularProgress style={{ margin: "auto" }} />
        <span>Checking for Saved Data</span>
      </div>
    ) : (
      OnboardingDataProvider(
        {
          botId: formData.bot ? formData.bot.id : "",
          goToNextStepFunc,
          setGoToNextStepFunc,
          setTestCustomerId,
          testCustomerId,
          realGreenApiKey,
          editingProgramId,
          rgZipCodes,
          rgCodes,
          loadingRG,
          employees,
          marketingCodes,
          callReasons,
          callStatuses,
          companies,
          routes,
          isRealGreen,
          serviceTypes,
          crmProgramCodes: rgPrograms,
          setEditingPriceTableId,
          setEditingProgramId,
          setEditingOfferId,
          editingKeys,
          botQuestions: formData.botQuestions ? formData.botQuestions : [],
          serviceQuestions: formData.serviceQuestions
            ? formData.serviceQuestions
            : [],
          setEditingQuestionIndex,
          setEditingServiceQuestionIndex,
          company: formData["company"] ? formData["company"] : {},
          programs: formData.programs ? formData.programs : {},
          priceTables: formData.priceTables ? formData.priceTables : {},
          offers: formData.offers ? formData.offers : {},
          botSettings: formData.bot ? formData.bot : {}
        },
        <Fragment>
          {isLawnbotEmployee && (
            <DevPanel
              companyId={companyId}
              companyName={
                formData && formData.company ? formData.company.companyName : ""
              }
            />
          )}
          <StepWizard>{steps}</StepWizard>
        </Fragment>
      )
    );
  } else {
    return (
      <div
        style={{
          height: "100px",
          textAlign: "center",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto"
        }}
      >
        Something went wrong. Please refresh or contact support.
      </div>
    );
  }
}

export default Onboarding;
